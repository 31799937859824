export default {
  adults: [
    // { text: 'AVP America Open', value: 'AVPA_2020' }, // 16
    // { text: 'AVPNext 5K - 14,999 / U26', value: 'AVP_N_5_2020' }, // 8
    // { text: 'AVPNext GOLD 15K-25K', value: 'AVP_N_15_2020' }, // 9
    // { text: 'AVPNext GOLD 25K+', value: 'AVP_N_25_2020' }, // 104

    { text: 'Amateur', value: 'AVPA_2023' },
    { text: 'AVP Next $8K+', value: 'AVP_NEXT_2024' },
    { text: 'Pro $500K+', value: 'AVP_P_500K_2023' },
    { text: 'Pro $499-$400K', value: 'AVP_P_400K_2023' },
    { text: 'Pro $399-$300K', value: 'AVP_P_300K_2023' },
    { text: 'Pro $299-$125K', value: 'AVP_P_125K_2023' },
    { text: 'Pro $124-$50K', value: 'AVP_P_50K_2023' },
    { text: 'Semi-Pro $49+-$20K', value: 'AVP_SP_20K_2023' },
    { text: 'Semi-Pro $19-$10K', value: 'AVP_SP_10K_2023' },
    { text: 'International $500K +', value: 'AVP_IP_500K_2023' },
    { text: 'International $499-$400K', value: 'AVP_IP_400K_2024' },
    { text: 'International $399-$300K', value: 'AVP_IP_300K_2024' },
    { text: 'International $299-$125K', value: 'AVP_IP_125K_2023' },
    { text: 'International $124-$50K', value: 'AVP_IP_50K_2024' },
    { text: 'International $49-$20K', value: 'AVP_IP_20K_2024' },
    { text: 'International $19-$10K', value: 'AVP_IP_10K_2023' },
    { text: 'International $9,999-$5K', value: 'AVP_IP_5K_2023' },
    { text: 'NORCECA', value: 'AVP_NOR' }
  ],
  juniors: [
    // { text: '5 Star', value: 'AVP_5STAR_2020' }, // 11
    // { text: '4 Star', value: 'AVP_4STAR_2020' }, // 12
    // { text: '3 Star', value: 'AVP_3STAR_2020' }, // 13
    // { text: '2 Star', value: 'AVP_2STAR_2020' }, // 14
    // { text: '1 Star', value: 'AVP_1STAR_2020' }, // 15

    { text: 'Level 4', value: 'AVP_4STAR_2023' },
    { text: 'Level 3', value: 'AVP_3STAR_2023' },
    { text: 'Level 2', value: 'AVP_2STAR_2023' },
    { text: 'Level 1', value: 'AVP_1STAR_2023' }
  ],
  memberships: [
    { text: 'Gold', value: 'GOLD', n: 3, e: 'This event requires a Gold membership' },
    { text: 'Silver', value: 'SILVER', n: 2, e: 'This event requires a Silver or Gold membership' },
    { text: 'Bronze', value: 'BRONZE', n: 1 },
    { text: 'Any', value: null, n: 0 }
  ]
}
