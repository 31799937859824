import Game from './Game'
import sum from 'lodash.sum'
import firstBy from 'thenby'
import DtoUpdate from './DtoUpdate'
import Team from './Team'
import moment from 'moment'
import MatchDescriptor from './MatchDescriptor'
import { routeHelper } from './HelperFunctions'
import { intersection, isSuperset } from '@/helpers/SetFunctions'
import flatten from '@/helpers/ArrayFlatten'

export default class Match {
  id = 0
  poolId = null
  bracketId = null
  number = 0
  court = null
  homeTeam = null
  homeTeamIds = null
  awayTeam = null
  awayTeamIds = null
  refTeam = null
  _startTime = null
  isMatch = false
  isBye = false
  games = []
  manualRefTeam = null
  manualRefId = null
  title = null
  courtOrder = null
  meta = null
  dtModified = null

  constructor (sdk, dto, type) {
    this.sdk = sdk
    this.type = type
    if (dto) {
      this.update(dto)
    }
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    if (this.dtModified) {
      if (!dto.dtModified) return
      const a = +this.dtModified
      const b = +dto.dtModified
      if (a > b) return
    }

    const exclude = ['games', 'homeTeam', 'awayTeam', 'refTeam']
    DtoUpdate(dto, this, exclude)

    if (dto.games) {
      if (this.games.length === 0) {
        this.games = dto.games.sort(firstBy('number')).map(g => new Game(this.sdk, g))
      }
      dto.games.forEach(g => this.gameUpdate(g))
      if (this.games.length > 3) {
        console.log('GAMES')
      }
    }
    if (dto.homeTeam) {
      this.homeTeam = new Team(this.sdk, dto.homeTeam, this.type)
    }
    if (dto.awayTeam) {
      this.awayTeam = new Team(this.sdk, dto.awayTeam, this.type)
    }
    if (dto.refTeam) {
      this.refTeam = new Team(this.sdk, dto.refTeam, this.type)
    }
    this._dto = null
    this.oDto = null
  }

  gameUpdate (dto) {
    const game = this.games.find(g => (dto.id && g.id === dto.id) || (g.number === dto.number))
    if (game) {
      if (game.dtModified) {
        if (!dto.dtModified) return
        const a = +game.dtModified
        const b = +dto.dtModified
        if (a > b) {
          console.log('old update')
          return
        }
      }
      game.update(dto)
      // game.id = dto.id
      // const zero = dto.home || dto.away
      // game.home = dto.home || (zero ? 0 : null)
      // game.away = dto.away || (zero ? 0 : null)
      // game.dtModified = dto.dtModified
      // game._winner = dto._winner
    } else {
      this.games.push(new Game(this.sdk, dto))
      this.games = this.games.sort(firstBy('number'))
    }
  }

  cleanGames () {
    const numbers = [...new Set(this.games.map(m => m.number))]
    numbers.forEach(n => {
      const all = this.games.filter(f => f.number === n)
      if (all.length > 1) {

      }
    })
  }

  edit () {
    this._dto = JSON.stringify(this.dto)
    this.oDto = JSON.stringify(this.fullDto)
  }

  restore () {
    if (this.oDto) this.update(this.oDto)
    this._dto = null
    this.oDto = null
  }

  patch (dto) {
    return this.sdk.patch.match(dto)
  }

  isBetween (ids) {
    return this.homeTeam && ids.includes(this.homeTeam.id) && this.awayTeam && ids.includes(this.awayTeam.id)
  }

  isBetweenTeamId (ids) {
    return this.homeTeam && ids.includes(this.homeTeam.teamId) && this.awayTeam && ids.includes(this.awayTeam.teamId)
  }

  isBetweenTeamId2 (ids) {
    const i = new Set(ids)
    if (this.homeTeamIds && this.awayTeamIds) {
      const h = new Set(this.homeTeamIds)
      const a = new Set(this.awayTeamIds)
      const ih = intersection(i, h)
      const ia = intersection(i, a)
      return ih.size > 0 && ia.size > 0
    }
    return false
  }

  isWithTeamId (ids) {
    const i = new Set(ids)
    if (this.homeTeamIds && this.awayTeamIds) {
      const h = new Set(this.homeTeamIds)
      const a = new Set(this.awayTeamIds)
      const ih = isSuperset(h, i)
      const ia = isSuperset(a, i)
      return ih || ia
    }
    return false
  }

  updateMatchMetaP (tournament, division, day, pool) {
    const pn = `${(pool.name || pool.number)}`
    const poolRounds = division.days.filter(f => f.flights.length).length
    const titleArray = [division.name, poolRounds > 1 ? day.name + ' ' : null, tournament.isTournament ? `${pn.includes('Pool') ? '' : 'Pool'} ${pn}` : null].filter(f => f)

    this.meta = {
      tournament: {
        name: tournament.name,
        id: tournament.id
      },
      division: {
        name: division.name,
        id: division.id,
        gender: division.gender.name.toLowerCase().startsWith('w') || division.gender.name.toLowerCase().startsWith('g') ? 0 : 1
      },
      round: {
        name: day.name,
        id: day.id
      },
      parent: {
        name: pool.name,
        id: pool.id,
        type: 'pool'
      },
      titleArray: titleArray,
      name: `Match ${this.number}`,
      length: pool.setting && pool.setting.minutesPerMatch,
      route: routeHelper(tournament, division, day, this)
    }
  }

  getMatchMetaP (tournament, division, day, pool) {
    const pn = `${(pool.name || pool.number)}`
    const poolRounds = division.days.filter(f => f.flights.length).length
    const titleArray = [division.name, poolRounds > 1 ? day.name + ' ' : null, tournament.isTournament ? `${pn.includes('Pool') ? '' : 'Pool'} ${pn}` : null, `Match ${this.number}`].filter(f => f)
    const home = this.homeTeam && division.teams.find(f => f.id === this.homeTeam.teamId)
    const away = this.awayTeam && division.teams.find(f => f.id === this.awayTeam.teamId)
    const ref = this.manualRefId ? tournament.teams.find(f => f.id === this.manualRefId) : (this.refTeam ? this.refTeam.teamId : this.refTeamId || this.manualRefId) && tournament.teams.find(f => f.id === (this.refTeam ? this.refTeam.teamId : this.refTeamId || this.manualRefId))
    // const ref = (this.refTeam ? this.refTeam.teamId : this.refTeamId || this.manualRefId) && division.teams.find(f => f.id === (this.refTeam ? this.refTeam.teamId : this.refTeamId || this.manualRefId))
    const roundJProps = tournament.jProps && tournament.jProps.rounds && tournament.jProps.rounds[day.id]
    const hideRefs = roundJProps && roundJProps.noRefs
    return {
      tournament: {
        name: tournament.name,
        id: tournament.id
      },
      division: {
        name: division.name,
        id: division.id,
        gender: division.gender.name.toLowerCase().startsWith('w') || division.gender.name.toLowerCase().startsWith('g') ? 0 : 1
      },
      round: {
        name: day.name,
        id: day.id
      },
      parent: {
        name: pool.name,
        id: pool.id,
        type: 'pool'
      },
      titleArray: titleArray,
      name: this.getDualDesc() || `Match ${this.number}`,
      length: pool.setting && pool.setting.minutesPerMatch,
      route: routeHelper(tournament, division, day, this),
      court: this.court,
      startTime: this.startTime,
      get endTime () {
        return this.startTime.add(this.length, 'm')
      },
      dayWithMo: this.dayWithMo,
      id: this.id,
      status: this.status,
      home: home ? home.name : null,
      homePlayers: home ? this.getPlayers(home, true) : null,
      away: away ? away.name : null,
      awayPlayers: home ? this.getPlayers(away, true) : null,
      ref: (!hideRefs && ref) ? ref.name : null,
      winner: this.winner,
      number: this.number,
      dtModified: this.dtModified
    }
  }

  updateMatchMetaB (tournament, division, day, bracket) {
    this.meta = {
      tournament: {
        name: tournament.name,
        id: tournament.id
      },
      division: {
        name: division.name,
        id: division.id
      },
      round: {
        name: day.name,
        id: day.id
      },
      parent: {
        name: this.isWinners ? (!bracket.losers.length || bracket.hasPlaceBracket ? day.name : 'Winners Bracket') : `${bracket.losersName}`,
        id: bracket.id,
        type: 'bracket'
      },
      titleArray: [division.name, day.name, bracket.hasPlaceBracket ? this.isWinners ? 'Winners' : bracket.losersName : null].filter(f => f),
      name: `Match ${this.displayNumber} (${bracket.getMatchTitle(this)})`,
      length: this.isWinners ? bracket.winnersMatchSettings && bracket.winnersMatchSettings.minutesPerMatch : bracket.losersMatchSettings && bracket.losersMatchSettings ? bracket.losersMatchSettings.minutesPerMatch : bracket.winnersMatchSettings && bracket.winnersMatchSettings.minutesPerMatch,
      route: routeHelper(tournament, division, day, this)
    }
  }

  getMatchMetaB (tournament, division, day, bracket) {
    const home = this.homeTeam && division.teams.find(f => f.id === this.homeTeam.teamId)
    const away = this.awayTeam && division.teams.find(f => f.id === this.awayTeam.teamId)
    const manualLength = this.settings && this.settings.minutesPerMatch
    const ref = this.manualRefId ? tournament.teams.find(f => f.id === this.manualRefId) : (this.refTeam ? this.refTeam.teamId : this.refTeamId || this.manualRefId) && tournament.teams.find(f => f.id === (this.refTeam ? this.refTeam.teamId : this.refTeamId || this.manualRefId))
    // const ref = (this.refTeam ? this.refTeam.teamId : this.refTeamId || this.manualRefId) && division.teams.find(f => f.id === (this.refTeam ? this.refTeam.teamId : this.refTeamId || this.manualRefId))
    return {
      tournament: {
        name: tournament.name,
        id: tournament.id
      },
      division: {
        name: division.name,
        id: division.id,
        gender: division.gender.name.toLowerCase().startsWith('w') || division.gender.name.toLowerCase().startsWith('g') ? 0 : 1
      },
      round: {
        name: day.name,
        id: day.id
      },
      parent: {
        name: this.isWinners ? (!bracket.losers.length || bracket.hasPlaceBracket ? day.name : 'Winners Bracket') : `${bracket.losersName}`,
        id: bracket.id,
        type: 'bracket'
      },
      titleArray: [division.name, day.name, bracket.hasPlaceBracket ? this.isWinners ? 'Winners' : bracket.losersName : null, `Match ${this.displayNumber}`].filter(f => f),
      name: this.getDualDesc(bracket) || `Match ${this.displayNumber} (${bracket.getMatchTitle(this)})`,
      length: manualLength || (this.isWinners ? bracket.winnersMatchSettings && bracket.winnersMatchSettings.minutesPerMatch : bracket.losersMatchSettings && bracket.losersMatchSettings ? bracket.losersMatchSettings.minutesPerMatch : bracket.winnersMatchSettings && bracket.winnersMatchSettings.minutesPerMatch),
      route: routeHelper(tournament, division, day, this),
      court: this.court,
      startTime: this.startTime,
      get endTime () {
        return this.startTime.add(this.length, 'm')
      },
      dayWithMo: this.dayWithMo,
      id: this.id,
      status: this.status,
      home: home ? home.name : null,
      homePlayers: home ? this.getPlayers(home, true) : null,
      away: away ? away.name : null,
      awayPlayers: home ? this.getPlayers(away, true) : null,
      ref: ref ? ref.name : null,
      winner: this.winner,
      number: this.number,
      dtModified: this.dtModified
    }
  }

  getPlayers (team, fullname) {
    if (!team) return false
    const lu = this.lineups && this.lineups.filter(f => f.teamId === this.homeTeam.teamId)
    if (lu && lu.length > 0) {
      const l = flatten(lu.map(m => m.players))
      const p = l && team.players && team.players.filter(f => l.includes(f.id))
      p && p.sort(firstBy('lastName').thenBy('firstName'))
      return p && p.map(m => `${!fullname ? m.lastName : m.name}${m.jersey ? ' #' + m.jersey : ''}`).join('/')
    }
    return team.players.map(m => `${!fullname ? m.lastName : m.name}${m.jersey ? ' #' + m.jersey : ''}`).join('/')
  }

  // getters-setters
  set startTime (val) {
    this._startTime = val ? val === 'now' ? moment().format('YYYY-MM-DD[T]HH:mm[Z]') : moment.utc(val).format('YYYY-MM-DD[T]HH:mm[Z]') : null
  }

  get startTime () {
    return this._startTime ? moment.utc(this._startTime) : null
  }

  get endTime () {
    return this.startTime && this.meta && this.meta.length && this.startTime.add(this.meta.length, 'm')
  }

  get slotGap () {
    if (!this.homeTeam || !this.awayTeam || this.type !== 'pool') return 0
    return Math.abs(this.homeTeam.slot - this.awayTeam.slot)
  }

  // getters
  get day () {
    return this.startTime ? this.startTime.format('ddd Do') : null
  }

  get dayWithMo () {
    return this.startTime ? this.startTime.format('MMM Do YYYY') : null
  }

  get time () {
    return this.startTime ? this.startTime.format('LT') : null
  }

  get unix () {
    return this.startTime ? this.startTime.unix() : null
  }

  get dirty () {
    return this._dto !== JSON.stringify(this.dto)
  }

  get dto () {
    return {
      id: this.id,
      poolId: this.poolId,
      bracketId: this.bracketId,
      number: this.number,
      court: this.court || null,
      homeTeam: this.homeTeam ? this.homeTeam.id : null,
      homeTeamIds: this.homeTeamIds && this.homeTeamIds.length ? this.homeTeamIds : null,
      awayTeam: this.awayTeam ? this.awayTeam.id : null,
      awayTeamIds: this.awayTeamIds && this.awayTeamIds.length ? this.awayTeamIds : null,
      refTeam: this.refTeam && this.refTeam.id > 0 ? this.refTeam.id : null,
      manualRefId: this.manualRefTeam ? this.manualRefTeam.id : null,
      startTime: this.startTime,
      isMatch: this.isMatch,
      games: this.games.filter(f => f.status || f.id).map(g => g.dto),
      settings: this.settings
    }
  }

  get liveStartDto () {
    return {
      id: this.id,
      poolId: this.poolId,
      bracketId: this.bracketId,
      number: this.number,
      court: this.court || null,
      homeTeam: this.homeTeam ? this.homeTeam.id : null,
      homeTeamIds: this.homeTeamIds && this.homeTeamIds.length ? this.homeTeamIds : null,
      awayTeam: this.awayTeam ? this.awayTeam.id : null,
      awayTeamIds: this.awayTeamIds && this.awayTeamIds.length ? this.awayTeamIds : null,
      refTeam: this.refTeam ? this.refTeam.id : null,
      manualRefId: this.manualRefTeam ? this.manualRefTeam.id : null,
      startTime: this.startTime,
      isMatch: this.isMatch,
      games: this.games.map(g => g.dto),
      settings: this.settings
    }
  }

  get fullDto () {
    return {
      id: this.id,
      poolId: this.poolId,
      bracketId: this.bracketId,
      number: this.number,
      court: this.court,
      homeTeam: this.homeTeam.dto,
      homeTeamIds: this.homeTeamIds && this.homeTeamIds.length ? this.homeTeamIds : null,
      awayTeam: this.awayTeam.dto,
      awayTeamIds: this.awayTeamIds && this.awayTeamIds.length ? this.awayTeamIds : null,
      refTeam: this.refTeam ? this.refTeam.dto : null,
      startTime: this.startTime,
      isMatch: this.isMatch,
      games: this.games.map(g => g.dto),
      settings: this.settings
    }
  }

  get setsNeededForWin () {
    return this.isMatch ? Math.ceil(this.games.length / 2) : this.games.length
  }

  get isForfeit () {
    return (this.awayTeam && this.awayTeam.forfeit) || (this.homeTeam && this.homeTeam.forfeit)
  }

  get complete () {
    if (this.isBye || this.isForfeit) return true
    if (this.games.length === 0) return false
    const completeSets = this.games.filter(f => !!f.winner)
    if (!this.isMatch) return completeSets.length === this.games.length
    if (completeSets.length < this.setsNeededForWin) return false

    return this.homeSetWins >= this.setsNeededForWin || this.awaySetWins >= this.setsNeededForWin
  }

  get completeIn () {
    return this.complete ? this.games.filter(f => !!f.winner).length : 9999
  }

  get isTimed () {
    return !!this.games.find(f => f.to === 999)
  }

  get winner () {
    if (this.isForfeit) return null
    if (this.isBye) return this.homeMap === 'Bye' && this.awayMap === 'Bye' ? null : this.homeMap !== 'Bye' ? 'home' : 'away'
    if (this.isTimed) {
      return !this.complete ? null : this.homePointsFor > this.awayPointsFor ? 'home' : this.homePointsFor < this.awayPointsFor ? 'away' : null
    }
    if (!this.isMatch && this.games.length > 1) {
      return !this.complete ? null : this.homePointsFor > this.awayPointsFor ? 'home' : this.homePointsFor < this.awayPointsFor ? 'away' : 'split'
    }
    return !this.complete ? null : this.isMatch || this.games.length === 1 ? this.homeSetWins > this.awaySetWins ? 'home' : 'away' : null
  }

  get tbWinner () {
    if (!this.complete) return null
    if (this.isTimed) {
      return this.homePointsFor > this.awayPointsFor ? 'home' : this.homePointsFor < this.awayPointsFor ? 'away' : null
    }
    return this.isMatch || this.games.length === 1 ? this.winner : this.homeSetWins > this.awaySetWins ? 'home' : this.homeSetWins < this.awaySetWins ? 'away' : 'split'
  }

  get tbWinnerId () {
    return this.complete && !this.isForfeit ? this.tbWinner === 'home' ? this.homeTeam && this.homeTeam.id : this.awayTeam && this.awayTeam.id : null
  }

  get homeSetWins () {
    return this.isForfeit ? 0 : this.games.filter(f => f.winner === 'home').length
  }

  get homeSetLosses () {
    return this.awaySetWins
  }

  get homePointsFor () {
    return this.isForfeit ? 0 : sum(this.games.map(m => +m.home))
  }

  get homePointsAgainst () {
    return this.isForfeit ? 0 : sum(this.games.map(m => +m.away))
  }

  get awaySetWins () {
    return this.isForfeit ? 0 : this.games.filter(f => f.winner === 'away').length
  }

  get awaySetLosses () {
    return this.homeSetWins
  }

  get awayPointsFor () {
    return this.isForfeit ? 0 : sum(this.games.map(m => +m.away))
  }

  get awayPointsAgainst () {
    return this.isForfeit ? 0 : sum(this.games.map(m => +m.home))
  }

  get winningId () {
    return this.complete && !this.isForfeit ? this.winner === 'home' ? this.homeTeam && this.homeTeam.id : this.awayTeam && this.awayTeam.id : null
  }

  get losingId () {
    return this.complete && !this.isForfeit ? this.winner === 'away' ? this.homeTeam && this.homeTeam.id : this.awayTeam && this.awayTeam.id : null
  }

  get winningTeam () {
    return this.complete && !this.isForfeit ? this.winner === 'home' ? this.homeTeam : this.awayTeam : null
  }

  get losingTeam () {
    return this.complete && !this.isForfeit ? this.winner === 'away' ? this.homeTeam : this.awayTeam : null
  }

  get teamIds () {
    return [this.awayTeam.id, this.homeTeam.id]
  }

  get status () {
    return this.complete ? 'Complete' : this.games.filter(g => g.status).length > 0 ? 'Started' : null
  }

  get publicGames () {
    return this.complete ? this.games.filter(g => g.id && g.status) : this.games
  }

  get currentGame () {
    if (this.complete) return null
    return this.games.find(g => !g.complete)
  }

  get isKob () {
    return this.homeTeamIds.length > 0 || this.awayTeamIds.length > 0
  }

  get activeTeamIds () {
    const hIds = []
    this.homeTeam && hIds.push(this.homeTeam.teamId)
    this.homeTeamIds && this.homeTeamIds.length && hIds.push(...this.homeTeamIds)
    const aIds = []
    this.awayTeam && aIds.push(this.awayTeam.teamId)
    this.awayTeamIds && this.awayTeamIds.length && aIds.push(...this.awayTeamIds)
    return [...hIds, ...aIds]
  }

  get homeIds () {
    const hIds = []
    this.homeTeam && hIds.push(this.homeTeam.teamId)
    this.homeTeamIds && this.homeTeamIds.length && hIds.push(...this.homeTeamIds)
    return hIds
  }

  get awayIds () {
    const aIds = []
    this.awayTeam && aIds.push(this.awayTeam.teamId)
    this.awayTeamIds && this.awayTeamIds.length && aIds.push(...this.awayTeamIds)
    return aIds
  }

  get allTeamIds () {
    return [...this.activeTeamIds, (this.refTeam ? this.refTeam.teamId : this.refTeamId || this.manualRefId)]
  }

  get timeLineItems () {
    const items = []
    if (this.isBye) return items
    const hIds = []
    this.homeTeam && hIds.push(this.homeTeam.teamId)
    this.homeTeamIds && this.homeTeamIds.length && hIds.push(...this.homeTeamIds)
    const aIds = []
    this.awayTeam && aIds.push(this.awayTeam.teamId)
    this.awayTeamIds && this.awayTeamIds.length && aIds.push(...this.awayTeamIds)
    if (hIds.length) {
      items.push(...hIds.map(hId => {
        return {
          teamId: hId,
          what: this.complete ? this.tbWinner === 'home' ? 'Beat' : this.tbWinner === 'away' ? 'Lost to' : 'Split with' : 'Play',
          when: this.startTime,
          unix: this.startTime ? this.startTime.unix() : moment().add(1000, 'y').unix(),
          where: this.court,
          with: hIds.filter(f => f !== hId),
          who: aIds,
          status: this.status,
          matchN: this.type === 'bracket' ? this.displayNumber : this.number,
          roundN: this.type === 'bracket' ? this.round + 1 : null,
          wl: this.tbWinner ? this.tbWinner === 'home' ? 'W' : this.tbWinner === 'away' ? 'L' : 'S' : null,
          scores: this.publicGames.map(g => `${g.home || 0}-${g.away || 0}`),
          winners: this.isWinners,
          complete: this.complete
        }
      }))
    }
    if (aIds.length) {
      items.push(...aIds.map(aId => {
        return {
          teamId: aId,
          what: this.complete ? this.tbWinner === 'away' ? 'Beat' : this.tbWinner === 'home' ? 'Lost to' : 'Split with' : 'Play',
          when: this.startTime,
          unix: this.startTime ? this.startTime.unix() : moment().add(1000, 'y').unix(),
          where: this.court,
          with: aIds.filter(f => f !== aId),
          who: hIds,
          status: this.status,
          matchN: this.type === 'bracket' ? this.displayNumber : this.number,
          roundN: this.type === 'bracket' ? this.round + 1 : null,
          wl: this.tbWinner ? this.tbWinner === 'away' ? 'W' : this.tbWinner === 'home' ? 'L' : 'S' : null,
          scores: this.publicGames.map(g => `${g.away || 0}-${g.home || 0}`),
          winners: this.isWinners,
          complete: this.complete
        }
      }))
    }
    if (this.refTeam || this.refTeamId || this.manualRefId) {
      items.push({
        teamId: this.manualRefId || (this.refTeam && this.refTeam.teamId ? this.refTeam.teamId : this.refTeamId || this.manualRefId),
        what: this.complete ? 'Reffed' : 'Ref',
        when: this.startTime,
        unix: this.startTime ? this.startTime.unix() : moment().add(1000, 'y').unix(),
        where: this.court,
        who: this.title,
        status: this.status,
        matchN: this.type === 'bracket' ? this.displayNumber : this.number,
        roundN: this.type === 'bracket' ? this.round + 1 : null,
        winners: this.isWinners,
        complete: this.complete
      })
    }
    return items
  }

  get timeLineItemsOG () {
    const items = []
    if (this.isBye) return items
    if (this.homeTeam) {
      items.push({
        teamId: this.homeTeam.teamId,
        what: 'Play',
        when: this.startTime,
        where: this.court,
        who: this.awayTeam ? this.awayTeam.teamId : null,
        status: this.status,
        matchN: this.type === 'bracket' ? this.displayNumber : this.number,
        roundN: this.type === 'bracket' ? this.round + 1 : null,
        wl: this.tbWinner ? this.tbWinner === 'home' ? 'W' : this.tbWinner === 'away' ? 'L' : 'S' : null,
        scores: this.publicGames.map(g => `${g.home || 0}-${g.away || 0}`)
      })
    }
    if (this.awayTeam) {
      items.push({
        teamId: this.awayTeam.teamId,
        what: 'Play',
        when: this.startTime,
        where: this.court,
        who: this.homeTeam ? this.homeTeam.teamId : null,
        status: this.status,
        matchN: this.type === 'bracket' ? this.displayNumber : this.number,
        roundN: this.type === 'bracket' ? this.round + 1 : null,
        wl: this.tbWinner ? this.tbWinner === 'away' ? 'W' : this.tbWinner === 'home' ? 'L' : 'S' : null,
        scores: this.publicGames.map(g => `${g.away || 0}-${g.home || 0}`)
      })
    }
    if (this.refTeam || this.manualRefId) {
      items.push({
        teamId: this.refTeam ? this.refTeam.teamId : this.manualRefId,
        what: 'Ref',
        when: this.startTime,
        where: this.court,
        who: this.title,
        status: this.status,
        matchN: this.type === 'bracket' ? this.displayNumber : this.number,
        roundN: this.type === 'bracket' ? this.round + 1 : null
      })
    }
    return items
  }

  get pointsPlayed () {
    return sum(this.games.filter(g => g.id && g.complete).map(m => m.to))
  }

  advanceText (bracket, lineText) {
    if (bracket.type === 'SINGLE_ELIM_WITH_5TH' && !this.isWinners && ![129, 130].includes(this.number)) return 'Winner places 5th'
    if (bracket.type === 'SINGLE_ELIM_WITH_5TH_NO_4TH' && !this.isWinners && ![128, 129].includes(this.number)) return 'Winner places 5th'
    if (bracket.type === 'SINGLE_ELIM_5TH_AND_7TH' && [129, 130].includes(this.number)) return 'Winner places 5th'
    if (this.isWinners && this.round === bracket.lastWinnersRoundN) return 'Champions!'
    const c = this.toCrossover(bracket)
    if (c || this.toFinals(bracket) || lineText) {
      let text = lineText || (c ? 'To Semifinals' : 'To Finals')
      if (bracket) {
        const pre = text.includes('Places') ? false : bracket.qText
        let seed = this.loserMap && this.loserMap.hi
        const bump = bracket.champions === 5 ? [2, 6, 7, 10, 11, 15, 18, 22, 23, 26, 27, 31, 34, 38, 39, 42, 43, 47, 50, 54, 55, 58, 59, 63, 66, 70, 71, 74, 75, 79, 82, 86, 87, 90, 91, 95, 98, 102, 103, 106, 107, 111, 114, 118, 119, 122, 123, 127] : [2, 7, 10, 15, 18, 23, 26, 31, 34, 39, 42, 47, 50, 55, 58, 63, 66, 71, 74, 79, 82, 87, 90, 95, 98, 103, 106, 111, 114, 119, 122, 127]
        const tri = [3, 5, 6, 9]
        if (tri.includes(bracket.champions)) {
          const adj = bump.filter(f => f < seed).length
          seed = seed - adj
        }
        if (pre && seed) {
          text = `${text} ${pre}${seed}`
        }
      }
      return text
    }
    return false
  }

  toCrossover (bracket) {
    return bracket && bracket.type.includes('CROSSOVER') && (this.number === 249 || this.number === 250)
  }

  toFinals (bracket) {
    return bracket && bracket.type === 'DOUBLE_ELIM' && this.number === 253
  }

  get isDual () {
    return this.number > 1000
  }

  getDualDesc (bracket) {
    if (!this.isDual) return
    const alpha = ['', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    if (bracket) {
      const p = bracket && bracket.duals.find(f => f.n === this.dualN)
      return `Match ${p.displayN}${alpha[this.dualMatchN]} (${bracket.getMatchTitle(this)})`
    }
    return `Match ${this.dualN}${alpha[this.dualMatchN]}`
  }

  get dualN () {
    return Math.floor(this.number / 1000)
  }

  get dualMatchN () {
    return this.number % 1000
  }

  get isTiebreak () {
    return this.title && this.title.toLowerCase().startsWith('tiebreak')
  }

  get matchDescription () {
    const d = new MatchDescriptor(this)
    return d.description
  }

  getMatchDescription (bracket) {
    if (!bracket.isDuals) {
      const d = new MatchDescriptor(this)
      return d.description
    } else {
      const a = bracket.matches.find(f => f.dualN === this.number && f.isDual)
      const dd = new MatchDescriptor(a)
      return dd.description
    }
  }

  dualDisplay (teamPlayers, fullname) {
    if (teamPlayers && teamPlayers.length === 2) {
      const p = teamPlayers
      p && p.sort(firstBy('lastName').thenBy('firstName'))
      return p.map(m => `${!fullname ? m.lastName : m.name}${m.jersey ? ' #' + m.jersey : ''}`).join('/')
    }
    const lp = this.lineupPlayers(teamPlayers, fullname)
    if (lp) return lp.join('/')
    if (!this.isDual) return false
    return `Pair ${this.pairN}`
  }

  lineupPlayers (teamPlayers, fullname) {
    const mlu = this.myLineups(teamPlayers)
    if (!mlu) return null
    const l = flatten(mlu.map(m => m.players))
    const p = l && teamPlayers && teamPlayers.filter(f => l.includes(f.id))
    if (p.length === 0) return null
    p && p.sort(firstBy('lastName').thenBy('firstName'))
    return p && p.map(m => `${!fullname ? m.lastName : m.name}${m.jersey ? ' #' + m.jersey : ''}`)
  }

  lineupsObj () {
    return this.lineups && this.lineups.length && {
      home: this.homeTeam && this.lineups.filter(f => f.teamId === this.homeTeam.teamId),
      away: this.awayTeam && this.lineups.filter(f => f.teamId === this.awayTeam.teamId)
    }
  }

  myLineups (teamPlayers) {
    const getPlayers = (lineups, fullname, teamPlayers) => {
      if (!lineups || lineups.length === 0) return null
      const l = flatten(lineups.map(m => m.players))
      const p = l && teamPlayers && teamPlayers.filter(f => l.includes(f.id))
      p && p.sort(firstBy('lastName').thenBy('firstName'))
      return p && p.map(m => fullname ? m.name : m.lastName).join('/')
    }
    return this.lineupsObj && {
      home: this.lineupsObj.home && flatten(this.lineupsObj.home.map(m => m.positions.filter(f => f.n === this.pairN && f.type === 'dual'))),
      get homePlayers () {
        return this.home && this.home.length > 0 && getPlayers(this.home)
      },
      away: this.lineupsObj.away && flatten(this.lineupsObj.away.map(m => m.positions.filter(f => f.n === this.pairN && f.type === 'dual'))),
      get awayPlayers () {
        return this.away && this.away.length > 0 && getPlayers(this.away)
      }
    }
  }

  getLineupPlayers (home, teams, fullname, players, noJersey) {
    var teamId = home ? this.homeTeam ? this.homeTeam.teamId : -1 : this.awayTeam ? this.awayTeam.teamId : -1
    var team = teams.find(f => f.id === teamId)
    if (!team) return false

    const result = {
      name: null,
      id: teamId,
      w: home ? this.winner === 'home' ? 1 : 0 : this.winner === 'away' ? 1 : 0,
      l: home ? this.winner === 'away' ? 1 : 0 : this.winner === 'home' ? 1 : 0,
      u: !this.winner ? 1 : 0
    }
    const lu = this.lineups && this.lineups.filter(f => f.teamId === team.id)
    if (lu && lu.length > 0) {
      const lup = flatten(lu.map(m => m.positions.filter(f => f.n === this.pairN && f.type === 'dual')))
      const l = flatten(lup.map(m => m.players))
      const p = l && team.players && team.players.filter(f => l.includes(f.id))
      p && p.sort(firstBy('lastName').thenBy('firstName'))
      if (p && p.length > 0) {
        result.name = p && p.map(m => `${!fullname ? m.lastName : m.name}${m.jersey && !noJersey ? ' #' + m.jersey : ''}`).join(' / ')
        return result
      }
    }
    result.name = team.players.length === players ? team.players.map(m => `${!fullname ? m.lastName : m.name}${m.jersey && !noJersey ? ' #' + m.jersey : ''}`).join(' / ') : team.name
    return result
  }

  getLogo (home, teams) {
    var teamId = home ? this.homeTeam ? this.homeTeam.teamId : -1 : this.awayTeam ? this.awayTeam.teamId : -1
    var team = teams.find(f => f.id === teamId)
    return team && team.logo
  }

  get pairN () {
    if (!this.isDual) return 1
    return (this.dualMatchN || 0)
  }

  get isCrossDivisional () {
    return this.title && this.title.startsWith('cd-')
  }

  get crossDivisionalIgnore () {
    return this.title && this.title.startsWith('cd-x-')
  }

  get mirrorRoundId () {
    if (this.isCrossDivisional) {
      const props = this.title.split('-')
      return +props.pop()
    }
    return null
  }
}
