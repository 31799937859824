<template>
  <v-container fluid class="pa-0">
    <!-- CART -->
    <v-row dense>
      <v-col cols="12" v-if="allCarts.length > 1">
        <v-select
          :items="cartSelect"
          v-model="selectedCart"
          label="Carts"
          solo
          hint="Select a cart"
          persistent-hint
          color="color3"
          item-color="color3"
        ></v-select>
      </v-col>
      <v-col cols="12">

        <v-window v-model="selectedCart" touchless>

          <v-window-item v-for="(cart, i) in allCarts" :key="cart.organization.id" :value="i">
            <cart-view
              :cart="cart"
              :ref="`cart_${i}`"
              :ads="orderIdAds"
              @check-update="onCheckUpdate"
              :processing="processing"
            ></cart-view>
          </v-window-item>

        </v-window>
      </v-col>
    </v-row>
    <v-expand-transition>
      <div v-if="cart.preAuthItems.length && cartStatus">
        <v-row>
          <v-col cols="12" class="pt-4">
            <v-card color="grey lighten-4">
              <v-toolbar color="color2 color2Text--text">
                <v-toolbar-title>Authorization</v-toolbar-title>
              </v-toolbar>
              <v-alert type="error" :value="true" prominent text>
                You have items in your cart that may be charged at a later date
              </v-alert>
              <v-card-text class="subtitle-1 pt-0">
                <div v-if="cart.preAuthMsg === 'waitlist'" class="font-weight-bold">
                  Waitlisted teams will be charged once the event director allows them in.
                </div>
                <div class="font-weight-bold" v-else>
                  {{cart.preAuthMsg}}
                </div>
                <div>
                  <p>A separate charge for each payment will appear on your credit card statement and a receipt for
                    each charge will be provided</p>
                  By clicking the checkbox below, I authorize {{cart.organization.name}} to charge my credit card
                  for all items listed above. I understand that some items may be charged to my card at a later date.
                  I understand that this authorization will remain in affect until I cancel it in writing.
                  I certify that I am the authorized user of this Credit Card and will not dispute these transactions.
                  <v-checkbox color="color3" label="I agree to the terms above" v-model="preAuthAck" :value="true"></v-checkbox>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
    <!-- REFUND POLICY -->
    <v-expand-transition>
      <div v-if="cart.refundPolicy">
        <v-row dense>
          <v-col cols="12">
            <v-card>
              <v-toolbar color="color2 color2Text--text" dense>
                <v-toolbar-title>Refund Policy</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-textarea
                  readonly filled
                  v-model="cart.refundPolicy"
                  hide-details
                ></v-textarea>
                <v-checkbox
                  hide-details
                  label="I understand and agree to the Refund Policy above"
                  v-model="refundAgree"
                  color="success"
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
    <!-- PAYMENT -->
    <v-expand-transition>
      <div v-if="!invalidRefund && !hasErrors && (cart.status) && !cart.orderId && !(cart.preAuthItems.length && !preAuthAck) && checks > 0">
        <v-row dense v-if="cartItems.length > 0 && !spoiled">
          <v-expand-transition>
            <v-col cols="12" class="pt-4">
              <v-card color="grey lighten-4">
                <v-toolbar color="color2 color2Text--text">
                  <v-toolbar-title v-if="!noPayment">
                    <v-icon class="mr-4 color2Text--text">fas fa-lock</v-icon>
                    Secure Payment
                  </v-toolbar-title>
                  <v-toolbar-title v-else>
                    <v-icon class="mr-4 color2Text--text">fas fa-clipboard-list</v-icon>
                    Confirmation Email
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text v-if="cartStatus" class="text-center">
                  <!-- NMI -->
                  <v-row dense v-if="nmi && !noPayment">
                    <v-col cols="12" md="6" offset-md="3" class="text-left title">
                      Payment Method
                    </v-col>
                    <v-col cols="12" md="6" offset-md="3">
                      <credit-card
                        ref="nmi"
                        @valid-change="onPaymentValidChange"
                        @token-created="tokenCreated"
                        @loading="nmiLoading = $event"
                        @zip-change="onZipChange"
                      ></credit-card>
                    </v-col>
                  </v-row>
                  <!-- EMAIL RECEIPT -->
                  <v-row dense>
                    <v-col cols="12" md="6" offset-md="3" class="text-left title">
                      Email {{noPayment ? 'Confirmation' : 'Receipt'}}
                    </v-col>
                    <v-col cols="12" md="6" offset-md="3">
                      <v-combobox
                        v-if="playerEmails.length > 0"
                        @update:search-input="$event => { emailReceipt = $event }"
                        :label="`Email ${noPayment ? 'Confirmation' : 'Receipt'} to:`"
                        :items="playerEmails"
                        class="eSelect"
                        required
                        :rules="[
                          () => $v.emailReceipt.required || 'An email is required',
                          () => $v.emailReceipt.email || 'A valid email is required'
                        ]"
                        autocomplete="off"
                        type="search"
                        color="color3"
                        item-color="color3"
                        solo
                        hint="Select an email or enter a new one"
                        persistent-hint
                      ></v-combobox>
                      <v-text-field
                        v-else
                        label="Email receipt to:"
                        v-model="emailReceipt"
                        required
                        :rules="[
                          () => $v.emailReceipt.required || 'An email is required',
                          () => $v.emailReceipt.email || 'A valid email is required'
                        ]"
                        autocomplete="off"
                        type="search"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- STRIPE CARD -->
                  <v-row dense v-if="!noPayment && cart.stripeAccount && !nmi">
                      <v-col class="text-left" cols="12" md="6" offset-md="3" :key="cart.stripeAccount">
                        <label :class="`${darkColor}--text`">Credit or Debit Card</label>
                        <stripe-elements
                          ref="elementsRef"
                          :pk="stripekey"
                          :stripeAccount="cart.stripeAccount"
                          :amount="total"
                          @token="tokenCreated"
                          @loading="stripeLoading = $event"
                        >
                        </stripe-elements>
                      </v-col>
                  </v-row>
                  <!-- ERROR -->
                  <v-row dense class="error--text">
                    <v-col cols="12" md="6" offset-md="3">
                      <v-alert type="error" prominent text transition="scale-transition" :value="!!paymentError">
                        <strong>Oops:</strong> {{paymentError}}
                      </v-alert>
                    </v-col>
                  </v-row>
                  <!-- SUBMIT BUTTONS -->
                  <v-row dense>
                    <v-col cols="12" md="6" offset-md="3">
                      <v-btn
                        v-if="!noPayment"
                        color="color3 color3Text--text"
                        class="ml-0"
                        :disabled="!valid"
                        :loading="processing || stripeLoading || nmiLoading"
                        @click="submitPayment"
                        >Submit payment
                      </v-btn>
                      <v-btn
                        v-else
                        color="color3 color3Text--text"
                        class="ml-0"
                        :disabled="!valid"
                        :loading="processing || stripeLoading || nmiLoading"
                        @click="processCart"
                        >Submit
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- PAY LATER -->
                  <v-row dense v-if="cart.canPayLater">
                    <v-col cols="12" md="6" offset-md="3">
                      <v-switch label="Pay Later" v-model="payLater" color="success"></v-switch>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-expand-transition>
        </v-row>
      </div>
    </v-expand-transition>
    <v-row class="d-flex justify-center">
      <v-col
        v-for="ad in myAds"
        :key="ad.id"
        cols="12"
        sm="6"
        xl="4"
        style="min-height: 195px;"
      >
        <v-card class="fill-height">
          <ad-card :ad="ad"></ad-card>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense v-if="user && user.id === 1">
      <v-col cols="12">
        <v-btn color="success" @click.stop="validateBag" :loading="validating">Validate</v-btn>
      </v-col>
    </v-row>

    <!-- ERROR DIALOG -->
    <v-dialog
      v-model="serverError"
      scrollable
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="error white--text">
          <v-icon>fas fa-bug</v-icon>
          <v-toolbar-title>Server Error</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="subtitle-1">
          We apologize but we have seem to hit an unknown server error.
          <br>
          <br>
          We have processed your payment, saved your cart, and sent an urgent text message to our tech team.
          <br>
          <br>
          Someone will be in touch shortly, with your confirmation number.
          <br>
          <br>
          We apologize, for any confusion. Feel free to email us at <a href="mailto:carterror@volleyballlife.com">carterror@volleyballlife.com</a> for any question.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="color3" text @click.stop="serverError = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import StripeElements from '@/components/Stripe/Elements'
import * as mutations from '@/store/MutationTypes'
import * as actions from '@/store/ActionTypes'
import CartItem from '@/classes/CartItem'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import Cart from '@/classes/Cart'
import CartView from '@/components/Cart/CartView'
import AdCard from '@/components/Ads/AdCard.vue'
import CreditCard from '@/components/NMI'

export default {
  props: ['organizationId'],
  mixins: [validationMixin],
  validations () {
    return {
      emailReceipt: { required, email }
    }
  },
  data () {
    return {
      selectedCart: 0,
      paymentError: null,
      processing: false,
      stripeLoading: false,
      nmiLoading: false,
      token: null,
      complete: false,
      focus: false,
      stripeOptions: {
      },
      emailReceipt: null,
      validating: false,
      serverError: false,
      preAuthAck: false,
      refundAgree: false,
      ads: [],
      paymentValid: false,
      zip: null,
      checks: 0,
      payLater: false
    }
  },
  computed: {
    ...mapGetters(['user', 'carts', 'getCart', 'darkColor', 'liveUpdates', 'liveUpdateStatus', 'subdomain']),
    cartSelect () {
      return this.carts.map((c, i) => {
        return {
          text: c.organization.name,
          value: i
        }
      })
    },
    stripekey () {
      // return process.env.NODE_ENV === 'production' ? 'pk_live_0Egtaqiqypfs3i3fcOCSls49' : 'pk_test_ow84DPhRZgYFYFJHBogIxDuw'
      return 'pk_live_0Egtaqiqypfs3i3fcOCSls49'
    },
    nmi () {
      return this.cart.paymentType === 'nmi'
    },
    invalidRefund () {
      return this.cart.refundPolicy && !this.refundAgree
    },
    valid () { return !this.$v.$invalid && (this.noPayment || !this.nmi || this.paymentValid) && !!this.bag.emailReceiptTo },
    allCarts () {
      return this.carts.map(c => new Cart(c))
    },
    cart () {
      const c = this.carts[this.selectedCart]
      return new Cart(c)
    },
    cartStatus () {
      return this.cart && this.cart.status
    },
    paymentType () {
      return this.cart.paymentType
    },
    cartItems () {
      return this.cart ? this.cart.items : []
    },
    jot () {
      return this.bag && this.bag.items.filter(f => f.jot).map(m => m.jot)
    },
    playerEmails () {
      var list = []
      if (this.user) {
        list = this.user.emails.map(m => m.address)
      }
      this.cartItems.forEach((item) => {
        item = new CartItem(item)
        list = list.concat(item.playerEmails)
      })
      return list
    },
    total () {
      return this.cart && this.cart.amounts.total
    },
    bag () {
      const dto = {
        id: 0,
        organizationId: this.cart.organization.id,
        items: this.cartItems,
        paymentToken: null,
        nmiPaymentToken: null,
        total: this.total,
        emailReceiptTo: this.emailReceipt,
        promo: this.cart.promo
      }
      if (this.payLater) {
        if (dto.promo) {
          dto.promo += '~pl~'
        } else {
          dto.promo = '~pl~'
        }
      }
      if (this.token) {
        if (this.nmi) {
          dto.nmiPaymentToken = this.token
          dto.zip = this.zip
        } else {
          dto.paymentToken = {
            id: this.token.id,
            card_id: this.token.card.id,
            card_last4: this.token.card.last4,
            card_brand: this.token.card.brand,
            client_ip: this.token.client_ip
          }
        }
      }
      return dto
    },
    spoiled () {
      return this.cartItems.filter(f => f.status && f.status === 'Closed').length
    },
    noPayment () {
      return this.total <= 0 || this.payLater // || this.user && this.user.vbl
    },
    hasErrors () {
      return this.cartItems.filter(f => f.error).length
    },
    hasElite () {
      var e = this.cartItems.filter(f => f.type === 'MEM').find(f => {
        const p = f.product && JSON.parse(f.product)
        return p && p.details && p.details.name && p.details.name.toLowerCase() === 'elite'
      })
      return !!e
    },
    myAds () {
      return this.cart && this.cart.orderId ? this.orderIdAds : this.checkOutAds
    },
    checkOutAds () {
      return this.ads.filter(f => f.locations && f.locations.includes('checkOut'))
    },
    orderIdAds () {
      return this.ads.filter(f => f.locations && f.locations.includes('orderId'))
    }
  },
  methods: {
    onCheckUpdate (v) {
      this.checks = v
    },
    onZipChange (v) {
      this.zip = v
    },
    onPaymentValidChange (v) {
      this.paymentValid = v
    },
    submitPayment () {
      this.paymentError = null
      this.nmi ? this.$refs.nmi.getToken() : this.$refs.elementsRef.submit()
    },
    tokenCreated (token) {
      this.token = token
      console.log('token created')
      this.processCart()
    },
    processCart () {
      this.processing = true
      this.$VBL.cart.process(this.bag)
        .then((r) => {
          if (r.data.paid) {
            const payload = {
              organizationId: this.cart.organization.id,
              orderId: r.data.orderId,
              emailReceipt: this.emailReceipt,
              jot: this.jot
            }
            this.$store.dispatch(actions.PURCHASE_COMPLETE, payload)
            this.token = null
          } else {
            this.confirmCardPayment(r.data.clientSecret, r.data.orderId, r.data.intentType)
          }
        })
        .catch((e) => {
          const r = e.response.data
          if (r.validationErrors) {
            this.setErrors(r.errors)
          } else {
            this.paymentError = r.errorMessage
          }
          this.processing = false
        })
    },
    confirmCardPayment (clientSecret, orderId, intentType) {
      const stripe = this.$refs.elementsRef.stripe
      const card = this.$refs.elementsRef.card
      const fnc = intentType === 'setup' ? stripe.confirmCardSetup : stripe.confirmCardPayment
      if (clientSecret && stripe && card) {
        fnc(clientSecret, {
          payment_method: {
            card: card
          }
        })
          .then(r => {
            console.log(r)
            if (r.error) {
              this.paymentError = r.error.message
              this.processing = false
            } else {
              const payload = {
                organizationId: this.cart.organization.id,
                orderId: orderId,
                emailReceipt: this.emailReceipt,
                jot: this.jot
              }
              this.$store.dispatch(actions.PURCHASE_COMPLETE, payload)
            }
          })
      }
    },
    setErrors (errors) {
      errors.forEach(e => {
        if (e.id) {
          const i = this.cartItems.find(f => f.id === e.id)
          if (i) {
            i.error = e.error
            this.$store.commit(mutations.UPDATE_CART_ITEM, i)
          }
        } else {
          this.paymentError = e.error
        }
      })
    },
    clearErrors () {
      this.paymentError = null
      this.cartItems.forEach(i => {
        i.error = false
        this.$store.commit(mutations.UPDATE_CART_ITEM, i)
      })
    },
    getCartStatus () {
      const cartRef = this.$refs && this.$refs[`cart${this.selectedCart}`]
      if (cartRef) cartRef.getCartStatus()
    },
    getAds () {
      if (this.liveUpdateStatus !== 'Connected') {
        setTimeout(this.getAds, 500)
        return
      }
      this.liveUpdates.invoke('FetchAds', this.subdomain)
        .then(ads => {
          this.ads = ads.map(m => JSON.parse(m))
        })
    },
    validateBag () {
      this.validating = true
      this.clearErrors()
      this.$VBL.cart.validateBag(this.bag)
        .then(r => {
          if (r.data && r.data.errors) {
            this.setErrors(r.data.errors)
          }
        })
        .catch(e => console.log(e))
        .finally(() => { this.validating = false })
    }
  },
  mounted () {
    this.getAds()
    this.$store.commit(mutations.CLEAN_CARTS)
  },
  watch: {
    'cart.orderId': function (val) {
      if (val) {
        this.$nextTick(() => {
          this.processing = false
        })
      }
    },
    processing: function (val) {
      const mutation = val ? mutations.LOADING : mutations.LOADING_COMPLETE
      this.$store.commit(mutation, 'CHECKOUT')
    }
  },
  components: {
    StripeElements,
    CartView,
    AdCard,
    CreditCard
  },
  destroyed () {
    this.$store.commit(mutations.CLEAN_CARTS)
  }
}
</script>

<style scoped>
.link {
  cursor: pointer;
  font-size: smaller;
}
.eSelect {
  background-color: white;
  border-radius: 4px;
}
.stripe-card {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #152a69;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.stripe-card.complete {
  border-color: green;
}
.stripe-card--focus {
  box-shadow: 0 1px 3px 0 #0e6fcf;
}

.stripe-card--invalid {
  border-color: #fa755a;
}

.stripe-card--webkit-autofill {
  background-color: #fefde5 !important;
}
>>> .v-dialog {
  width: auto !important
}
</style>
