import Division from './TournamentDivision'
// import Day from './TournamentDay'
import moment from 'moment'
import uniq from 'lodash.uniq'
import uniqBy from 'lodash.uniqby'
import sum from 'lodash.sum'
import Registration from './TournamentRegistration'
import * as StatusEnum from '../classes/TournamentStatus'
import flatten from '@/helpers/ArrayFlatten'
import DtoUpdate from './DtoUpdate'
import firstBy from 'thenby'
import max from 'lodash.max'
import Fabs from './Temp/Fabs'
import Currency from '@/classes/Currency'
import GroupedStandings from '@/classes/Standings/GroupedStandings'
import LeagueSettings from './LeagueSettings'
import { union } from '@/helpers/SetFunctions'
import { routeHelper } from '@/classes/HelperFunctions'
import CartFeeFlattener from './CartFeeFlattener'
import CartFee from './CartFee'
import Checklist from './Checklist'
import AVP from '@/classes/AvpSanctioning'
import { qrCodeBase } from '@/classes/_URL'

export default class Tournament {
  constructor (sdk, dto) {
    this.sdk = sdk
    this.description = ''
    this.divisions = []
    this.externalRegistrationUrl = ''
    this.id = 0
    this.isPublic = false
    this.name = ''
    this.organizationId = 0
    this.organization = null
    this.paymentOrganizationId = 0
    this.paymentOrganization = null
    this.statusId = 0
    // this.divisionTemplate = new Division(this.sdk, {days: [new Day(this.sdk)]})
    this.coverPhotoId = null
    this.containCover = false
    this.coverPhotoUrl = null
    this._startDate = null
    this._endDate = null
    this.manage = false
    this.sanctioningBody = null
    this.emailNote = null
    this.qualifiers = []
    this.locked = false
    this.bidProgram = null
    this.publicCanNav = true
    this.hasPassword = false
    this.refIds = []
    this.props = []
    this.fabs = []
    this.jsonProps = null
    this.jProps = {
      cartFees: []
    }
    this.eliteOverride = false
    this.pointSystems = []
    this.bids = null
    this.bidStatus = null
    this.onlineCheckin = []
    this.noOnlineCheckin = []
    this.agreementStatuses = []
    this.streams = []
    this.standings = []
    this.showRatings = []
    this.droppedDivisions = []
    this.checklists = []

    if (dto) {
      this.update(dto)
    }
    this.getStreams()
    this.getChecklists()
  }

  // methods
  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['divisions', 'divisionTemplate', 'streams', 'checklists']
    DtoUpdate(dto, this, exclude)

    this.checkBids()

    if (dto.divisions) {
      dto.divisions.forEach(division => {
        const t = division.id > 0 ? this.divisions.find(f => f.id === division.id) : null
        if (t) {
          t.update(division)
        } else {
          this.addDivision(division)
        }
      })
    }

    this.sortDivisions()

    if (!this.coverPhotoUrl) {
      this.coverPhotoUrl = 'https://vblimages.blob.core.windows.net/images/bigTDmeeting.jpg'
    }
    if (this.id) {
      this.fabs = Fabs.filter(f => f.tournamentId === this.id)
    }
    if (this.jsonProps) {
      this.jProps = Object.assign({ cartFees: [] }, JSON.parse(this.jsonProps))
      this.initStandings()
    }
    if (!this.jProps) this.jProps = {}
    if (!this.jProps.cartFees) this.jProps.cartFees = []
    if (dto.streams) {
      dto.streams.forEach(stream => {
        const i = this.streams.findIndex(f => f.id === stream.id)
        if (i < 0) {
          this.streams.push(this.mapStream(stream))
        } else {
          this.streams.splice(i, 1, this.mapStream(stream))
        }
      })
    }
    if (dto.checklists) {
      dto.checklists.forEach(l => this.updateOrAddChecklist(l))
    }
  }

  updateOrAddChecklist (dto) {
    const l = this.checklists.find(f => f.id === dto.id)
    if (!l) {
      this.checklists.push(new Checklist(dto))
    } else {
      l.update(dto)
    }
  }

  addDivision (d) {
    if (this.droppedDivisions.includes(d.id)) return
    this.divisions.push(new Division(this.sdk, d))
  }

  dropDivision (d) {
    const i = this.divisions.findIndex(f => f.id === d.id)
    if (i > -1) {
      this.droppedDivisions.push(d.id)
      this.divisions.splice(i, 1)
    }
  }

  initStandings () {
    const standings = this.jProps && this.jProps.standings
    if (standings) {
      standings.forEach((s, i) => {
        if (this.standings.length > i) {
          this.standings[i].update(JSON.stringify(s))
        } else {
          this.standings.push(new GroupedStandings(JSON.stringify(s)))
        }
      })
    } else {
      this.standings = []
    }
  }

  checkBids () {
    if (!this.bidStatus) {
      this.getBids()
    }
  }

  getBids () {
    this.bidStatus = 'loading'
    this.sdk.tournament.checkBids(this.id)
      .then(r => { this.bids = r.data })
      .catch(e => console.log(e.response))
      .finally(() => { this.bidStatus = 'done' })
  }

  getAgreementStatus () {
    return new Promise((resolve, reject) => {
      if (this.id === 0) return resolve()
      this.sdk.agreements.tournament.getStatus(this.id)
        .then(r => {
          this.agreementStatuses = r.data
          return resolve()
        })
        .catch(e => { return reject(e) })
    })
  }

  getChecklists () {
    return new Promise((resolve, reject) => {
      if (this.id === 0) return resolve()
      this.sdk.tournament.checklists.getAll(this.id)
        .then(r => {
          if (r.data && r.data.length) {
            r.data.forEach(dto => this.updateOrAddChecklist(dto))
          }
          return resolve()
        })
        .catch(e => { return reject(e) })
    })
  }

  getStreams () {
    return new Promise((resolve, reject) => {
      if (this.id === 0) return resolve()
      this.sdk.tournament.streams.getAll(this.id)
        .then(r => {
          if (r.data && r.data.length) {
            this.streams = r.data.map(m => this.mapStream(m))
          }
          return resolve()
        })
        .catch(e => { return reject(e) })
    })
  }

  mapAllStream () {
    this.streams.forEach(s => this.mapStream(s))
  }

  mapStream (s) {
    const division = this.divisions.find(f => f.id === s.divisionId)
    const round = s.poolId ? this.rounds.find(f => f.pools.map(p => p.id).includes(s.poolId)) : this.rounds.find(f => f.brackets.map(p => p.id).includes(s.bracketId))
    const pool = s.poolId ? this.pools.find(f => f.id === s.poolId) : null
    const bracket = s.bracketId ? this.brackets.find(f => f.id === s.bracketId) : null
    const match = this.matches.find(f => f.poolId === s.poolId && f.bracketId === s.bracketId && f.number === s.matchId)
    const home = match && match.homeTeam ? this.teams.find(d => d.id === match.homeTeam.teamId) : null
    const away = match && match.awayTeam ? this.teams.find(d => d.id === match.awayTeam.teamId) : null
    s.started = moment(s.dtModified)
    s.unix = moment(s.dtModified).unix()
    s.division = division
    s.round = round
    s.pool = pool
    s.bracket = bracket
    s.match = match
    s.home = home
    s.away = away
    s.matchType = s.poolId ? 'Pool' : 'Bracket'
    s.complete = () => match && match.complete
    s.teams = home && away ? `${home.name} vs ${away.name}` : 'NA'
    return s
  }

  lockMe (cb) {
    const dto = {
      id: this.id,
      locked: true
    }
    this.sdk.patch.tournament(dto)
      .then(r => {
        if (cb) cb()
      })
      .catch(e => console.log(e.response))
  }

  saveJProps () {
    const dto = {
      id: this.id,
      jsonProps: JSON.stringify(this.jProps)
    }
    return this.sdk.patch.tournament(dto)
  }

  setProp (p, val) {
    this.props = this.props.filter(f => !f.includes(p))
    if (val) {
      this.props.push(p)
    }
  }

  replaceDivision (division) {
    var replacement = new Division(this.sdk, division)
    var i = this.divisions.findIndex(f => f.id === replacement.id)
    if (i > -1) {
      this.divisions[i] = replacement
    } else {
      this.divisions.push(replacement)
      this.sortDivisions()
    }
  }

  sortDivisions () {
    this.divisions.sort(
      firstBy('propOrder')
        .thenBy('isCS')
        .thenBy('isAdult')
        .thenBy('numOfPlayers')
        .thenBy('gName', { ignoreCase: true, direction: -1 })
        .thenBy('dOrder')
        .thenBy('divisionsString', { ignoreCase: true })
    )
  }

  changeDivisions (teamId, newDivisionId) {
    const currentDivision = this.divisions.find((d) => {
      return d.teams.find((t) => {
        return t.id === teamId
      })
    })
    if (!currentDivision) return

    const newDivision = this.divisions.find((d) => {
      return d.id === newDivisionId
    })
    if (!newDivision) return

    const i = currentDivision.teams.findIndex((t) => {
      return t.id === teamId
    })
    if (i < 0) return
    const t = currentDivision.teams[i]
    t.seed = null
    newDivision.teams.push(t)
    currentDivision.teams.splice(i, 1)
    this.sortDivisions()
  }

  newRegistration (divisionId) {
    let dto = null
    if (typeof divisionId !== 'number') {
      dto = divisionId
      divisionId = null
    }

    const r = new Registration(this.sdk, dto)
    r.tournamentId = this.id
    r.organization = this.paymentOrganization || this.organization
    if (divisionId) {
      const d = this.divisions.find(f => f.id === divisionId)
      if (d) r.setDivision(d)
    }
    return r
  }

  cascadeTemplateChange () {
    this.divisions.forEach((d, i) => {
      d.updateFromTemplate(this.divisionTemplate)
    })
  }

  updateTeams (dto) {
    // dto = aray of {tournamentDivisionId: 0, teams: []}
    dto.forEach((item) => {
      const division = this.divisions.find((d) => {
        return d.id === item.tournamentDivisionId
      })
      if (division) {
        division.teams = item.teams
      }
    })
  }

  saveResult () {
    return this.sdk.team.saveResults(this.resultsDto)
  }

  findTeam (teamId) {
    const currentDivision = this.divisions.find((d) => {
      return d.teams.find((t) => {
        return t.id === teamId
      })
    })
    return currentDivision.teams.find((t) => {
      return t.id === teamId
    })
  }

  getDivision (divisionId) {
    return this.divisions.find(d => d.id === divisionId)
  }

  getDivisionByRoundId (dayId) {
    return this.divisions.find((d) => {
      return d.days.find(day => {
        return day.id === dayId
      })
    })
  }

  getDivisionByTeam (teamId) {
    return this.divisions.find(d => d.teams.find(t => t.id === teamId))
  }

  getDivisionJProps (id) {
    return this.jProps && this.jProps.divisions && this.jProps.divisions[id]
  }

  getDivisionJProp (id, prop) {
    return this.jProps && this.jProps.divisions && this.jProps.divisions[id] && this.jProps.divisions[id][prop]
  }

  getDay (dayId) {
    const division = this.divisions.find((d) => {
      return d.days.find(day => {
        return day.id === dayId
      })
    })
    return division.days.find(day => {
      return day.id === dayId
    })
  }

  deleteRound (dayId) {
    const division = this.divisions.find((d) => {
      return d.days.find(day => {
        return day.id === dayId
      })
    })
    if (!division) return
    const i = division.days.findIndex(day => {
      return day.id === dayId
    })
    if (i >= 0) {
      division.days.splice(i, 1)
    }
  }

  resetRound (dayId) {
    const division = this.divisions.find((d) => {
      return d.days.find(day => {
        return day.id === dayId
      })
    })
    division && division.resetRound(dayId)
  }

  deleteSelector (id) {
    const division = this.divisions.find((d) => {
      return d.days.find(day => {
        return day.teamSelectors.find(sel => {
          return sel.id === id
        })
      })
    })
    const day = division.days.find(day => {
      return day.teamSelectors.find(sel => {
        return sel.id === id
      })
    })
    const i = day.teamSelectors.findIndex(sel => {
      return sel.id === id
    })
    if (i) {
      day.teamSelectors.splice(i, 1)
    }
  }

  addWindow () {
    this.publicDivisions.forEach(d => d.addWindow())
  }

  deleteWindow (i) {
    this.publicDivisions.forEach(d => d.deleteWindow(i))
  }

  checkPlayerAgreements (playerId, divisionId) {
    if (this.agreementStatuses.length === 0) return true
    var bad = this.agreementStatuses.find(a => {
      const d = a.divisions.find(f => f.divisionId === divisionId)
      if (d.unsent.includes(playerId)) return true
      if (d.unsigned.includes(playerId)) return true
    })
    return !bad
  }

  getPlayerAgreements (playerId, divisionId) {
    if (this.agreementStatuses.length === 0) return []
    return this.agreementStatuses.filter(a => a.divisions.find(f => f.divisionId === divisionId)).map(m => {
      const d = m.divisions.find(f => f.divisionId === divisionId)
      const status = d.signed.includes(playerId) ? 'Signed' : d.signedPrev.includes(playerId) ? 'Signed Previous Version' : d.unsigned.includes(playerId) ? 'Unsigned' : d.unsent.includes(playerId) ? 'Unsent' : 'Unsigned'
      return {
        id: m.agreementId,
        name: m.agreement,
        status: status
      }
    })
  }

  get noAgreeNoCheckin () {
    return this.props.includes('noAgreeNoCheckin')
  }

  formatMoney (amount) {
    return Currency(amount, this.organization.currency)
  }

  updateMatchMeta () {
    this.divisions.forEach(d => d.updateMatchMeta(this))
  }

  getMatchMeta () {
    return flatten(this.divisions.map(d => d.getMatchMeta(this)))
  }

  getCartFees (divisionId) {
    if (!(this.jProps && this.jProps.cartFees)) return []
    return this.jProps.cartFees.filter(f => f.divisionIds.includes('*') || f.divisionIds.includes(divisionId))
  }

  getCartFeesForReg (reg) {
    return CartFeeFlattener(reg, this.jProps.cartFees)
  }

  addCartFee (dto) {
    if (!this.jProps) this.jProps = {}
    if (!this.jProps.cartFees) this.jProps.cartFees = []
    this.jProps.cartFees.push(dto)
  }

  updateCartFee (oldVal, newVal) {
    const i = this.jProps.cartFees.findIndex(f => JSON.stringify(new CartFee(f)) === JSON.stringify(oldVal))
    if (i > -1) {
      this.jProps.cartFees.splice(i, 1, newVal)
    }
  }

  deleteCartFee (fee) {
    const i = this.jProps.cartFees.findIndex(f => JSON.stringify(new CartFee(f)) === JSON.stringify(fee))
    if (i > -1) {
      this.jProps.cartFees.splice(i, 1)
    }
  }

  getCustomFields (divisionId) {
    const fields = this.getDivisionJProp(divisionId, 'additionalFields')
    const newFields = this.jProps && this.jProps.customFields
    const result = []
    if (fields) {
      result.push(...fields)
    }
    if (newFields) {
      result.push(...newFields.filter(f => f.divisions.includes(divisionId) || f.divisions.includes('*')))
    }
    return result
  }

  // getter setter
  get hasCartFees () {
    return this.jProps && this.jProps.cartFees && this.jProps.cartFees.length > 0
  }

  get checkMembership () {
    if (this.hasCartFees) {
      const i = this.jProps.cartFees.findIndex(f => f.nonMembers.length > 0 || f.members.length > 0)
      return i > -1
    }
    return false
  }

  get addOns () {
    return this.jProps && this.jProps.addOns
  }

  get mappedStreams () {
    return this.streams.filter(f => f.division && !f.division.canceled)
  }

  get startDate () {
    return this._startDate ? moment(this._startDate) : null
  }

  set startDate (val) {
    this._startDate = val ? moment(val).format('YYYY-MM-DD') : null
  }

  get endDate () {
    return this._endDate ? moment(this._endDate) : null
  }

  set endDate (val) {
    this._endDate = val ? moment(val).format('YYYY-MM-DD') : null
  }

  get dtRefundCutoff () {
    return this._dtRefundCutoff ? moment(this._dtRefundCutoff) : null
  }

  set dtRefundCutoff (val) {
    this._dtRefundCutoff = val ? moment(val).format('YYYY-MM-DD') : null
  }

  get dtRosterCutoff () {
    const p = this.props.find(f => f.startsWith('dtRosterCutoff:'))?.replace('dtRosterCutoff:', '')
    return p ? moment(p) : this.startDate
  }

  set dtRosterCutoff (val) {
    this.props = this.props.filter(f => !f.startsWith('dtRosterCutoff'))
    if (val) {
      this.props.push(`dtRosterCutoff:${moment(val).format('YYYY-MM-DD')}`)
    }
  }

  get membersOnly () {
    const oId = this.organizationId || this.organization.id
    if (this.isBVNE && ![177, 179].includes(oId) && !this.props.includes('membersOnlyOverride')) {
      return true
    }
    if (this.isBVCA && !this.props.includes('membersOnlyOverride')) {
      return true
    }
    return this.props.includes('membersOnly')
  }

  set membersOnly (val) {
    this.props = this.props.filter(f => f !== 'membersOnly')
    if (val) {
      this.props.push('membersOnly')
    }
  }

  get membershipUsername () {
    const p = this.props.find(f => f.startsWith('membersOnly-'))
    return p && p.replace('membersOnly-', '')
  }

  set membershipUsername (val) {
    this.props = this.props.filter(f => !f.startsWith('membersOnly'))
    if (val) {
      this.props.push('membersOnly')
      this.props.push(`membersOnly-${val}`)
    }
  }

  get membershipLevels () {
    let p = this.props.find(f => f.startsWith('membersLevels-'))
    if (p) {
      p = p.replace('membersLevels-', '')
      return p.split('-')
    }
    return false
  }

  set membershipLevels (val) {
    this.props = this.props.filter(f => !f.startsWith('membersLevels-'))
    if (val) {
      val = val.filter(f => !!f)
      if (val && val.length) {
        this.props.push(`membersLevels-${val.join('-')}`)
      }
    }
  }

  get membershipLevelsOverrideSanction () {
    let p = this.props.find(f => f.startsWith('membershipLevelsOverrideSanction-'))
    if (p) {
      p = p.replace('membershipLevelsOverrideSanction-', '')
      return p.split('-')
    }
    return false
  }

  set membershipLevelsOverrideSanction (val) {
    this.props = this.props.filter(f => !f.startsWith('membershipLevelsOverrideSanction-'))
    if (val) {
      val = val.filter(f => !!f)
      if (val && val.length) {
        this.props.push(`membershipLevelsOverrideSanction-${val.join('-')}`)
      }
    }
  }

  get useAvpSanctionOptions () {
    if ((!this.startDate || this.season >= 2020)) {
      return this.isAVP || this.isUSAV
    }
    return false
  }

  get avpAdultSanction () {
    const a = this.props.find(f => f.includes('avpAdultSanction:'))
    return a ? a.split(':').pop() : 'AVPA_2023'
  }

  set avpAdultSanction (val) {
    this.props = this.props.filter(f => !f.includes('avpAdultSanction:'))
    if (val) {
      this.props.push(`avpAdultSanction:${val}`)
    }
  }

  get avpJrSanction () {
    const a = this.props.find(f => f.includes('avpJrSanction:'))
    return a ? a.split(':').pop() : 'AVP_1STAR_2023'
  }

  set avpJrSanction (val) {
    this.props = this.props.filter(f => !f.includes('avpJrSanction:'))
    if (val) {
      this.props.push(`avpJrSanction:${val}`)
    }
  }

  get avpMembership () {
    const a = this.props.find(f => f.includes('avpMembership:'))
    return a ? a.split(':').pop() : null
  }

  get avpMembershipObj () {
    const a = this.avpMembership
    return a ? AVP.memberships.find(f => f.value === a.toUpperCase()) : null
  }

  set avpMembership (val) {
    this.props = this.props.filter(f => !f.includes('avpMembership:'))
    if (val) {
      this.props.push(`avpMembership:${val}`)
    }
  }

  get secondarySBs () {
    var props = this.props.filter(f => f.startsWith('dualSanction-')).map(m => m.replace('dualSanction-', ''))
    return props
  }

  get allSanctionBodies () {
    const a = []
    if (this.sanctioningBody) {
      a.push(this.sanctioningBody, ...this.secondarySBs)
    }
    return a
  }

  // getters
  get publicDivisions () {
    return this.divisions.filter(f => f.isPublic)
  }

  get adminDivisions () {
    return this.divisions.filter(f => !f.canceled)
  }

  get refDivisions () {
    return this.divisions.filter(f => !f.canceled && f.isXrefs)
  }

  get orgRegFlowDivisions () {
    return this.publicDivisions.filter(f => f.orgRegFlow)
  }

  get allOrgRegFlow () {
    return this.orgRegFlowDivisions.length > 0
  }

  get bidDivisions () {
    return this.publicDivisions.filter(f => f.bidProgram)
  }

  get publicRounds () {
    return flatten(this.publicDivisions.map(m => m.publicRounds))
  }

  get freePlayRounds () {
    return flatten(this.publicDivisions.map(m => m.rounds.filter(f => f.freePlay)))
  }

  get season () {
    return this.startDate.month() + 1 >= 9 ? this.startDate.year() + 1 : this.startDate.year()
  }

  get unix () {
    return this.startDate.unix()
  }

  get checkUserTeams () {
    return !!this.publicDivisions.find(f => f.sportId === 1 && (f.isDuals || f.isNcaa))
  }

  get isNcaa () {
    return !!this.publicDivisions.find(f => f.isNcaa)
  }

  get isClubVClub () {
    return !!this.publicDivisions.find(f => f.isClubVClub)
  }

  // SIMPLES
  get simpleSport () {
    return uniq(this.publicDivisions.map(d => d.sportId)).length === 1
  }

  get simpleSurface () {
    return uniq(this.publicDivisions.map(d => d.surfaceId)).length === 1
  }

  get simpleStart () {
    const dates = this.publicDivisions.map(d => d.days[0].date ? d.days[0].date.format('YYYY-MM-DD') : null)
    if (this.startDate) dates.push(this.startDate.format('YYYY-MM-DD'))
    return uniq(dates).length === 1
  }

  get simpleCheckIn () {
    return uniq(this.publicDivisions.map(d => d.days[0].checkInTime)).length === 1
  }

  get simplePlay () {
    return uniq(this.publicDivisions.map(d => d.days[0].playTime)).length === 1
  }

  get simpleLocation () {
    return uniq(this.publicDivisions.map(d => d.location ? d.location.id : null)).length === 1
  }

  get simpleRefundPolicy () {
    return uniq(this.publicDivisions.map(d => d.refundPolicyId)).length === 1
  }

  registrationSimplicity (divisionId) {
    const windowCounts = uniq(this.publicDivisions.map(d => d.registrationWindows.length))

    const result = {
      count: divisionId ? true : windowCounts.length === 1,
      windows: [],
      get all () {
        var x = this.windows.find(f => !f.all)
        return !x
      }
    }
    for (let i = 0; i < max(windowCounts); i++) {
      result.windows.push({
        fee: divisionId ? true : uniq(this.publicDivisions.map(d => d.registrationWindows[i] && d.registrationWindows[i].fee)).length === 1,
        feeIsPerTeam: divisionId ? true : uniq(this.publicDivisions.map(d => d.registrationWindows[i] && d.registrationWindows[i].feeIsPerTeam)).length === 1,
        dtStart: divisionId ? true : uniq(this.publicDivisions.map(d => d.registrationWindows[i] && d.registrationWindows[i]._dtStart)).length === 1,
        dateStart: divisionId ? true : uniq(this.publicDivisions.map(d => d.registrationWindows[i] && d.registrationWindows[i].dtStart ? d.registrationWindows[i].dtStart.format('YYYY-MM-DD') : null)).length === 1,
        timeStart: divisionId ? true : uniq(this.publicDivisions.map(d => d.registrationWindows[i] && d.registrationWindows[i].dtStart ? d.registrationWindows[i].dtStart.format('HH:mm') : null)).length === 1,
        dtEnd: divisionId ? true : uniq(this.publicDivisions.map(d => d.registrationWindows[i] && d.registrationWindows[i]._dtEnd)).length === 1,
        timeEnd: divisionId ? true : uniq(this.publicDivisions.map(d => d.registrationWindows[i] && d.registrationWindows[i].dtEnd ? d.registrationWindows[i].dtEnd.format('HH:mm') : null)).length === 1,
        dateEnd: divisionId ? true : uniq(this.publicDivisions.map(d => d.registrationWindows[i] && d.registrationWindows[i].dtEnd ? d.registrationWindows[i].dtEnd.format('YYYY-MM-DD') : null)).length === 1,
        name: divisionId ? true : uniq(this.publicDivisions.map(d => d.registrationWindows[i] && d.registrationWindows[i].name)).length === 1,
        canPayAtEvent: divisionId ? true : uniq(this.publicDivisions.map(d => d.registrationWindows[i] && d.registrationWindows[i].canPayAtEvent)).length === 1,
        canProcessPayment: divisionId ? true : uniq(this.publicDivisions.map(d => d.registrationWindows[i] && d.registrationWindows[i].canProcessPayment)).length === 1,
        get all () {
          return this.fee && this.feeIsPerTeam && this.dateStart && this.dateEnd && this.timeStart && this.timeEnd && this.name && this.canPayAtEvent && this.canProcessPayment
        }
      })
    }

    return result
  }

  get regDeadline () {
    return this.simpleRegistration && this.publicDivisions.length && this.publicDivisions[0].currentRegistrationWindow.deadline
  }

  get simpleRefund () {
    return {
      date: uniq(this.publicDivisions.map(d => d.dtRefundCutoff ? d.dtRefundCutoff.format('YYYY-MM-DD') : null)).length === 1,
      time: uniq(this.publicDivisions.map(d => d.dtRefundCutoff ? d.dtRefundCutoff.format('HH:mm') : null)).length === 1
    }
  }

  get simpleRegistrationFields () {
    return uniq(this.publicDivisions.map(d => d.registrationFieldsCompareString)).length === 1
  }

  get simpleEmail () {
    return uniq(this.publicDivisions.map(d => d.emailNote)).length < 2
  }

  simpleFieldChoice (field, list) {
    if (list) {
      const notTheSame = this.publicDivisions.find(d => !d.registrationFields[list].includes(field))
      return !notTheSame
    }
    const inF = this.publicDivisions.find(d => d.registrationFields.fields.includes(field))
    const inRF = this.publicDivisions.find(d => d.registrationFields.requiredFields.includes(field))
    return !inF && !inRF
  }

  get smsEnabled () {
    const x = this.publicDivisions.find(d => d.registrationFields.requiredFields.includes('2FPhone'))
    return !!x
  }

  get dto () {
    return {
      description: this.description,
      divisions: this.divisions.map((d) => {
        return d.dto
      }),
      externalRegistrationUrl: this.externalRegistrationUrl,
      id: this.id,
      isPublic: this.isPublic,
      name: this.name,
      organization: this.organization,
      statusId: this.statusId,
      startDate: this._startDate,
      endDate: this._endDate,
      coverPhotoId: this.coverPhotoId,
      containCover: this.containCover,
      coverPhotoUrl: this.coverPhotoUrl,
      sanctioningBody: this.sanctioningBody,
      emailNote: this.emailNote,
      bidProgram: this.bidProgram ? { id: this.bidProgram.id } : null,
      publicCanNav: this.publicCanNav,
      refIds: this.refIds,
      props: this.props,
      jsonProps: this.jProps ? JSON.stringify(this.jProps) : null,
      pointSystems: this.pointSystems
    }
  }

  get resultsDto () {
    var results = []
    this.divisions.forEach((d) => {
      results = results.concat(d.resultsDto)
    })
    return results
  }

  get divisionHeaders () {
    return [
      { text: 'Type', value: 'ageName', align: 'left' },
      { text: 'Gender', value: 'genderName', align: 'left' },
      { text: 'Division', value: 'divisionName', align: 'left' },
      { text: 'Location', value: 'locationName', align: 'left' }
    ]
  }

  get ageCount () {
    if (this.divisions.length === 0) return false

    return uniq(this.divisions.map(d => d.ageType.name)).length > 1
  }

  get ageGroups () {
    return uniq(this.divisions.map(d => d.ageType.name))
  }

  get dates () {
    return [].concat(...this.publicDivisions.map(d => d.dates))
  }

  get uniqueDays () {
    return uniq(this.dates)
  }

  get dayCount () {
    return this.uniqueDays.length
  }

  get isOneDay () {
    return this._startDate === this._endDate
  }

  get startDateDisplay () {
    return this.startDate && moment(this.startDate).format('dddd, MMMM Do YYYY')
  }

  get divisionsString () {
    return uniq(this.publicDivisions.map(d => d.divisionsString)).join(', ')
  }

  get locationCount () {
    return uniq(this.locations.map(d => d.name)).length
  }

  get isOneLocation () {
    return this.locationCount === 1
  }

  get locations () {
    return uniqBy(this.publicDivisions.filter(f => f.location && f.location.id).map(d => d.location), 'id').sort(firstBy('name'))
  }

  get locationNames () {
    return uniq(this.locations.map(l => l.name))
  }

  get teamCount () {
    return sum(this.divisions.map(d => d.teamCount))
  }

  get waitlistCount () {
    return sum(this.divisions.map(d => d.waitlistTeams.length))
  }

  get dateStatus () {
    if (!this.startDate) return StatusEnum.UPCOMING
    if (this.startDate.isAfter()) return StatusEnum.UPCOMING
    if (moment().isBetween(this.startDate, this.endDate, 'd', '[]')) return StatusEnum.INPROCESS
    return StatusEnum.PAST
  }

  get divisionsWithTeams () {
    return this.divisions.filter(d => d.teamCount > 0 && !d.canceled)
  }

  get regOpen () {
    // if (!this.isPublic) return false
    let open = false
    if (!this.locations.length) return false
    this.publicDivisions.forEach((d) => {
      if (d.currentRegistrationWindow && !d.disableRegistration) {
        open = true
      }
    })
    return open
  }

  get isComplete () {
    return this.statusId === StatusEnum.COMPLETE
  }

  get readyToComplete () {
    return !!this.divisionsWithTeams.length && !this.divisionsWithTeams.filter(f => !f.complete && !f.looksComplete && !f.isCS).length
  }

  get isCanceled () {
    return this.statusId === StatusEnum.CANCELED || this.statusId === StatusEnum.DELETED
  }

  get isDeleted () {
    return this.statusId === StatusEnum.DELETED
  }

  get isPostponed () {
    return this.statusId === StatusEnum.POSTPONED
  }

  get isEditable () {
    return !this.isComplete && !this.isCanceled
  }

  get isUpcoming () {
    return this.dateStatus === StatusEnum.UPCOMING
  }

  get isPast () {
    return this.dateStatus === StatusEnum.PAST
  }

  get showStartTimes () {
    return this.props && this.props.includes('showStartTimes')
  }

  is (prop) {
    return this.props && this.props.includes(prop)
  }

  get isAAU () {
    return this.sanctioningBody && this.sanctioningBody.startsWith('AAU')
  }

  get isBVNE () {
    return this.sanctioningBody && this.sanctioningBody.startsWith('BVNE')
  }

  get isBVCA () {
    return this.sanctioningBody && this.sanctioningBody.startsWith('BVCA')
  }

  get isAVP () {
    return this.allSanctionBodies.filter(f => f.startsWith('AVP')).length > 0
  }

  get isUSAV () {
    return this.allSanctionBodies.includes('USAV')
  }

  get isShowcase () {
    return this.props && this.props.includes('isShowcase')
  }

  get isStreaming () {
    return this.props && this.props.includes('streaming')
  }

  get isTournament () {
    return !this.isCamp && !this.isClinic && !this.isTryout && !this.isLeague && !this.isYouthProgram && !this.isTrainingSession
  }

  get isCamp () {
    return this.props && this.props.includes('isCamp')
  }

  get isClinic () {
    return this.props && this.props.includes('isClinic')
  }

  get isTryout () {
    return this.props && this.props.includes('isTryout')
  }

  get isLeague () {
    return this.props && this.props.includes('isLeague')
  }

  get isYouthProgram () {
    return this.props && this.props.includes('isYouthProgram')
  }

  get isTrainingSession () {
    return this.props && this.props.includes('isTrainingSession')
  }

  get isLive () {
    return true // this.props && this.props.includes('live-scoring')
  }

  get strict () {
    return this.props && this.props.includes('strict')
  }

  get autoLock () {
    return this.props && this.props.includes('autoLock')
  }

  get printable () {
    // return this.props && this.props.includes('printable')
    return true
  }

  get isCompetitive () {
    return this.isLeague || this.isTournament
  }

  get privateOnly () {
    return this.isTrainingSession || this.sportId !== 1
  }

  get eventType () {
    return this.isTournament ? 'Tournament'
      : this.isLeague ? 'League'
        : this.isCamp ? 'Camp'
          : this.isClinic ? 'Clinic'
            : this.isTryout ? 'Tryout'
              : this.isTrainingSession ? 'Training Session'
                : this.isYouthProgram ? 'Youth Program'
                  : '???'
  }

  get participantType () {
    return (this.isTournament || this.isLeague) ? 'Team' : this.isCamp ? 'Participant' : this.isClinic ? 'Participant' : 'Player'
  }

  get teamType () {
    return this.isNcaa ? 'ncaa' : this.isClubVClub ? 'club' : null
  }

  get sanctionedBy () {
    var division = this.divisions && this.divisions.find((d) => {
      return d.sanctioningBodyId
    })
    return (division && division.sanctioningBodyId) || ''
  }

  get teamList () {
    let list = []
    this.divisions.forEach((d) => {
      list = list.concat(d.teamList)
    })
    return list
  }

  get teams () {
    return flatten(this.divisions.map(division => {
      return division.teams
    }))
  }

  get teamsWithDivision () {
    return flatten(this.divisions.map(division => {
      return division.teams.map(t => {
        return {
          id: t.id,
          team: t.name,
          division: division.name
        }
      })
    }))
  }

  get activeTeams () {
    return flatten(this.publicDivisions.map(division => {
      return division.activeTeams
    }))
  }

  get activeRefs () {
    return flatten(this.refDivisions.map(division => {
      return division.activeTeams
    }))
  }

  get activePlayers () {
    return flatten(this.publicDivisions.map(division => {
      return division.activeTeams.map(t => t.players)
    }))
  }

  get activePlayersNoCoach () {
    return flatten(this.publicDivisions.filter(f => !f.isCoaches).map(division => {
      return division.activeTeams.map(t => t.players)
    }))
  }

  get activePlayerList () {
    return flatten(this.publicDivisions.map(division => {
      return division.activeTeams.map(t => t.players.map(p => {
        return {
          id: p.playerProfileId || p.id,
          division: division.name,
          player: p,
          teamId: t.id
        }
      }))
    }))
  }

  get teamsOverview () {
    return flatten(this.publicDivisions.filter(f => !f.isCoaches).map(division => {
      return division.activeTeams.map(team => {
        return {
          team: team,
          division: {
            id: division.id,
            name: division.name,
            numOfPlayers: division.numOfPlayers,
            props: division.props
          },
          public: division.showTeams,
          next: null
        }
      })
    }))
  }

  get crossDivisionCheck () {
    return this.props.includes('crossDivisionCheck')
  }

  getTimeline (teamId) {
    const division = this.publicDivisions.find(f => f.activeTeams.find(t => t.id === teamId))
    if (!division) return null
    const tl = division.getTimeline(teamId, this.isLeague)

    if (this.crossDivisionCheck) {
      const otherDivisions = this.publicDivisions.filter(f => f.id !== division.id)
      const otherDivisionMatches = []
      otherDivisions.forEach(d => {
        const timeline = d.getTimeline(teamId, this.isLeague, true)
        if (timeline) {
          timeline.rounds.forEach(r => {
            if (r.matches.length) {
              otherDivisionMatches.push(...r.matches)
            }
          })
        }
      })
      if (otherDivisionMatches.length > 0) {
        const allMatches = flatten(tl.rounds.map(m => m.matches))
        allMatches.push(...otherDivisionMatches)
        const days = [...new Set(allMatches.map(m => m.when.format('dddd, MMM Do')))]
        const daysOG = [...new Set(allMatches.map(m => m.when.format('MMDDYYYY')))]
        console.log(days)
        console.log(daysOG)
        const newTimelineRounds = days.map(d => {
          return {
            description: null,
            dtStart: null,
            hideTimes: false,
            id: 0,
            matches: allMatches.filter(f => f.when.format('dddd, MMM Do') === d).sort(firstBy(firstBy('unix').thenBy('matchN'))),
            subtitle: null,
            title: d,
            to: null
          }
        })

        tl.rounds = newTimelineRounds
      }
    }
    return tl
  }

  get rounds () {
    return flatten(this.divisions.map(division => {
      return division.days
    }))
  }

  get selectors () {
    return flatten(this.divisions.map(division => {
      return division.days.map(day => {
        return day.teamSelectors
      })
    }))
  }

  get brackets () {
    return flatten(this.divisions.map(division => {
      return division.days.map(day => {
        return day.brackets
      })
    }))
  }

  get flights () {
    return flatten(this.divisions.map(division => {
      return division.days.map(day => {
        return day.flights
      })
    }))
  }

  get activeFlights () {
    return flatten(this.publicDivisions.map(division => {
      return division.publicRounds.map(day => {
        return day.flights
      })
    }))
  }

  get pools () {
    return flatten(this.divisions.map(division => {
      return division.days.map(day => {
        return day.flights.map(flight => {
          return flight.pools
        })
      })
    }))
  }

  get poolTeams () {
    return flatten(this.pools.map(pool => pool.teams))
  }

  get matches () {
    return this.poolMatches.concat(this.bracketMatches)
  }

  get allMatches () {
    return flatten(this.divisions.map(division => {
      return division.days.map(day => {
        return day.allMatches
      })
    }))
  }

  get publicMatches () {
    return flatten(this.publicRounds.map(day => {
      return day.allMatches
    })
    )
  }

  get duals () {
    const result = []
    if (this.pools && this.pools.length) {
      result.push(...flatten(this.pools.map(p => p.duals)))
    }
    if (this.brackets && this.brackets.length) {
      result.push(...flatten(this.brackets.map(p => p.duals)))
    }
    return result
  }

  get bracketMatches () {
    return flatten(this.divisions.map(division => {
      return division.days.map(day => {
        return day.brackets.map(bracket => {
          return [bracket.matches, bracket.placeMatches]
        })
      })
    }))
  }

  get bracketTimeline () {
    return flatten(this.divisions.map(division => {
      return division.days.map(day => {
        return day.brackets.map(bracket => {
          return bracket.matches.map(match => {
            return match.timeLineItems.map(t => {
              t.round = day.name
              t.roundN = day.number
              t.title = `Match: ${match.displayNumber} Bracket: ${bracket.name}`
              return t
            })
          })
        })
      })
    }))
  }

  get poolMatches () {
    return flatten(this.divisions.map(division => {
      return division.days.map(day => {
        return day.flights.map(flight => {
          return flight.pools.map(pool => {
            return pool.matches
          })
        })
      })
    }))
  }

  get crossDivMatches () {
    return this.matches ? this.matches.filter(f => f.isCrossDivisional) : []
  }

  get poolTimeline () {
    return flatten(this.divisions.map(division => {
      return division.days.map(day => {
        return day.flights.map(flight => {
          return flight.pools.map(pool => {
            return pool.matches.map(match => {
              return match.timeLineItems.map(t => {
                t.round = day.name
                t.roundN = day.number
                t.title = `Match: ${match.number} Pool: ${pool.name}`
                t.status = pool.locked ? 'Complete' : t.status
                return t
              })
            })
          })
        })
      })
    }))
  }

  get games () {
    return flatten(this.matches.map(m => m.games))
  }

  get courts () {
    const a = (this.matches && this.matches.filter(f => f.court).map(m => m.court)) || []
    const b = (this.jProps && this.jProps.courts) || []
    const all = union(new Set(a), new Set(b))
    return [...all].sort(firstBy(Number))
  }

  get genderOptions () {
    return uniqBy(this.publicDivisions.map(d => d.gender), 'id')
  }

  get divisionOptions () {
    return uniqBy(this.publicDivisions.map(d => d.division), 'id')
  }

  getDivisionFromMatch (matchId) {
    return matchId && this.divisions.find(div => {
      return div.days.find(day => {
        return day.allMatches.find(f => f.id === matchId)
      })
    })
  }

  getDayFromMatch (matchId) {
    return matchId && this.rounds.find(r => r.allMatches.find(f => f.id === matchId))
  }

  get fullTimeline () {
    const poolMatches = flatten(this.divisions.map(division => {
      const poolRounds = division.days.filter(f => f.flights.length).length
      return division.days.map(day => {
        return day.flights.length ? day.flights.map(flight => {
          return flight.pools.map(pool => {
            return pool.matches.map(match => {
              return {
                division: division.name,
                divisionId: division.id,
                round: day.name,
                roundId: day.id,
                pool: pool.name,
                nameArray: [poolRounds > 1 ? day.name + ' ' : null, `Pool ${pool.name}`, `Match ${match.number}`].filter(f => f),
                titleArray: [division.name, poolRounds > 1 ? day.name + ' ' : null, `${pool.name && pool.name.includes('Pool') ? '' : 'Pool'} ${pool.name}`].filter(f => f),
                match: match.number,
                day: match.dayWithMo,
                time: match.time,
                unix: match.unix,
                court: match.court,
                complete: match.complete,
                m: match,
                homeTeam: match.homeTeam ? this.teams.find(d => d.id === match.homeTeam.teamId) : null,
                awayTeam: match.awayTeam ? this.teams.find(d => d.id === match.awayTeam.teamId) : null,
                refTeam: match.refTeam ? this.teams.find(d => d.id === match.refTeam.teamId) : null,
                ref: {
                  pool: pool,
                  round: day,
                  division: division
                },
                route: {
                  text: 'Jump to pool',
                  to: {
                    name: 'pool-sheet',
                    params: {
                      tournamentId: this.id,
                      divisionId: division.id,
                      dayId: day.id,
                      poolId: pool.id
                    }
                  }
                }
              }
            })
          })
        }) : day.bracket ? day.bracket.matches.map(match => {
          return {
            division: division.name,
            divisionId: division.id,
            round: day.name,
            roundId: day.id,
            pool: null,
            nameArray: [day.name, day.bracket.hasPlaceBracket ? match.isWinners ? 'Winners' : day.bracket.losersName : null, `Match ${match.displayNumber}`].filter(f => f),
            titleArray: [division.name, day.name, day.bracket.hasPlaceBracket ? match.isWinners ? 'Winners' : day.bracket.losersName : null].filter(f => f),
            match: match.displayNumber,
            day: match.dayWithMo,
            time: match.time,
            unix: match.unix,
            court: match.court,
            complete: match.complete,
            m: match,
            homeTeam: match.homeTeam ? this.teams.find(d => d.id === match.homeTeam.teamId) : null,
            awayTeam: match.awayTeam ? this.teams.find(d => d.id === match.awayTeam.teamId) : null,
            refTeam: match.refTeam ? this.teams.find(d => d.id === match.refTeam.teamId) : null,
            ref: {
              bracket: day.bracket,
              round: day,
              division: division
            },
            route: {
              text: 'Jump to bracket',
              to: {
                name: 'bracket-home',
                params: {
                  tournamentId: this.id,
                  divisionId: division.id,
                  dayId: day.id,
                  poolId: day.bracket.id
                }
              }
            }
          }
        }) : []
      })
    }))
    return poolMatches.sort(firstBy('unix'))
  }

  get skinnyTimeline () {
    const t = this
    const poolMatches = flatten(this.divisions.map(division => {
      const poolRounds = division.days.filter(f => f.flights.length).length
      return division.days.map(day => {
        return day.flights.length ? day.flights.map(flight => {
          return flight.pools.map(pool => {
            return pool.matches.map(match => {
              const pn = `${(pool.name || pool.number)}`
              return {
                titleArray: [division.name, poolRounds > 1 ? day.name + ' ' : null, this.isTournament ? `${pn.includes('Pool') ? '' : 'Pool'} ${pn}` : null].filter(f => f),
                unix: match.unix,
                court: match.court,
                complete: match.complete,
                m: match,
                ref: {
                  pool: pool,
                  round: day,
                  division: division
                },
                route: routeHelper(t, division, day, match)
              }
            })
          })
        }) : day.bracket ? day.bracket.matches.map(match => {
          return {
            titleArray: [division.name, day.name, day.bracket.hasPlaceBracket ? match.isWinners ? 'Winners' : day.bracket.losersName : null].filter(f => f),
            unix: match.unix,
            court: match.court,
            complete: match.complete,
            m: match,
            ref: {
              bracket: day.bracket,
              round: day,
              division: division
            },
            route: routeHelper(t, division, day, match)
          }
        }) : []
      })
    }))
    return poolMatches.sort(firstBy('unix'))
  }

  get leagueSettings () {
    const courtsUsed = uniq(this.matches.map(m => m.court)).filter(f => !!f)
    return this.jProps && this.jProps.leagueSettings && new LeagueSettings(this.jProps.leagueSettings, courtsUsed)
  }

  get leagueTimeline () {
    if (!this.isLeague || !this.leagueSettings) return []
    const slots = this.leagueSettings.getAllSlots(this.rounds)
    const fullTimeline = this.fullTimeline
    var result = []
    slots.forEach(s => {
      const matches = fullTimeline.filter(f => f.day === s.dt.format('MMM Do YYYY') && f.time === s.dt.format('LT') && f.court === s.court)
      if (matches.length > 0) {
        result.push(...matches)
      } else {
        result.push({
          division: null,
          divisionId: null,
          round: null,
          roundId: null,
          pool: null,
          match: null,
          day: s.dt.format('MMM Do YYYY'),
          time: s.dt.format('h:mm A'),
          unix: s.dt.unix(),
          court: s.court,
          complete: false,
          m: null
        })
      }
    })
    return result
  }

  get leagueTimelineOg () {
    if (!this.isLeague || !this.leagueSettings) return []
    // const days = uniq(this.rounds.map(m => m.date.format('YYYY-MM-DD')))
    return flatten(
      this.divisions.map(division => {
        return division.rounds.map(day => {
          const dayWithMo = day.date.format('MMM Do YYYY')
          return this.leagueSettings.courtSlots.map(c => {
            const date = day.date.clone().utc()
            const time = moment(c.start, 'h:mm A')
            const dateTime = date.set({
              hour: time.get('hour'),
              minute: time.get('minute'),
              second: 0,
              millisecond: 0
            })
            const matches = this.fullTimeline.filter(f => f.roundId === day.id && f.unix === dateTime.unix() && f.court === c.court)
            if (matches.length) return matches
            return {
              division: division.name,
              divisionId: division.id,
              round: day.name,
              roundId: day.id,
              pool: null,
              match: null,
              day: dayWithMo,
              time: dateTime.format('h:mm A'),
              unix: dateTime.unix(),
              court: c.court,
              complete: false,
              m: null
            }
          })
        })
      })
    )
  }

  get loadOrder () {
    const divs = this.divisions.map(d => {
      return {
        name: d.name,
        id: d.id,
        date: d.startDate.format(),
        diff: d.startDate.diff(moment(), 'days'),
        diff2: d.startDate.diff(moment(), 'hours'),
        canceled: d.canceled,
        onDemand: d.props.includes('loadOnDemand') ? 1 : 0
      }
    })
    const r = divs.filter(f => !f.canceled && f.diff >= 0).sort(firstBy('onDemand').thenBy('diff').thenBy('diff2'))
    r.push(...divs.filter(f => !f.canceled && f.diff < 0).sort(firstBy('diff', -1).thenBy('diff2', -1)))
    r.push(...divs.filter(f => f.canceled))

    return r.map(m => m.id)
  }

  get qrCodePackage () {
    const docs = this.publicDivisions.map(d => {
      return {
        subtitle: this.name,
        title: d.name,
        desc: `${d.name} Home`,
        url: `${qrCodeBase}&url=https://${this.organization.username}.volleyballlife.com/tournament/${this.id}/division/${d.id}/home`
        // url: `${qrCodeBase}&url=https://aau.volleyballlife.com/tournament/${this.id}/division/${d.id}/home`
      }
    })
    docs.push(...this.courts.sort(firstBy(Number)).map(c => {
      return {
        title: `Court ${c}`,
        subtitle: this.name,
        url: `${qrCodeBase}&url=https://${this.organization.username}.volleyballlife.com/tournament/${this.id}/court/${c}`
        // url: `${qrCodeBase}&url=https://aau.volleyballlife.com/tournament/${this.id}/court/${c}`
      }
    }))
    return docs
  }

  get leaderboard () {
    const l = this.props.find(f => f.startsWith('leaderboard:'))
    return l && l.replace('leaderboard:', '')
  }

  get leaderboardTitle () {
    const l = this.props.find(f => f.startsWith('leaderboardTitle:'))
    return l ? l.replace('leaderboardTitle:', '').replace('_', ' ') : 'Leader Board'
  }

  get fullyHydrated () {
    return !this.divisions.find(f => !f.hydrated && !f.canceled)
  }

  get hasJuniors () {
    return !!this.publicDivisions.find(f => !f.isAdults)
  }

  get hasFinishes () {
    return !!this.activeTeams.find(f => f.finish > 0)
  }

  get reoccuringSettings () {
    return this.jProps && this.jProps.reoccuringSettings
  }

  set reoccuringSettings (val) {
    if (!this.jProps) this.jProps = {}
    this.jProps.reoccuringSettings = val
  }

  get openReg () {
    return this.isTrainingSession ? this.props.includes('allowDropIn') : true
  }

  get showLive () {
    return this.props.includes('showLive')
  }

  get redirect () {
    const p = this.props.find(f => f.startsWith('redirect-'))
    if (p) {
      const id = p.replace('redirect-', '')
      return id
    }
    return false
  }

  get reoccuring () {
    return this.isLeague || this.isTrainingSession
  }

  get calenderEvents () {
    console.log(this.dates)
    return this.reoccuring ? this.publicDivisions.map(d => {
      const date = d.days[0]._date
      return {
        id: d.id,
        name: this.eventType,
        start: date,
        end: date
      }
    }) : [{
      id: this.id,
      name: this.eventType,
      start: this.startDate.format('YYYY-MM-DD hh:mm'),
      end: this.endDate.format('YYYY-MM-DD hh:mm')
    }]
  }

  get hasResults () {
    const i = this.publicDivisions.findIndex(f => !f.noResults)
    return i > -1
  }

  loadOrgProps (force) {
    if (this.organization.props && !force) return

    this.sdk.http.get(`organization/${this.organization.username}/props`)
      .then(r => {
        this.organization.props = r.data
      })
  }

  getOrgProps (force) {
    if (this.organization.props && !force) {
      return new Promise((resolve) => {
        resolve({ data: this.organization.props })
      })
    }

    return this.sdk.http.get(`organization/${this.organization.username}/props`)
  }
}
